 @import "variables.scss";

.posters {
   width: 100%;
   // padding: 0 2rem 2rem 2rem;
}

.poster-label {
    display: $flex;
    justify-content: $center;
    margin-left: -32px;
    margin-top: 10px;
    font-weight: bold;
}

.slick-next:before,
.slick-prev:before {
    color: #2a7fc9;
    
}

.slick-prev {
    left: 0;
}

.slick-next {
    right: 0;
}

.slick-list {
    margin-left: 40px;
    margin-right: 15px;
    height: 360px;
}

.cabin-class {
    margin-top: 6%;
    h6 {
        color: $black;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.46px;
        line-height: 20px;
    }
    h5 {
        font-weight: bold;
    }
    .airplane {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
    }

    .economy {
        position: $absolute;
        left: 20%;
        top: 30%;
        h5 {
            color: #00539d;
        }
        .economy-bar {
            background-color: #00539d;
        }

    }

    .p-economy {

        position: $absolute;
        left: 49%;
        top: 29%;
        h5 {
            color: #79c2d0;
        }
        .p-economy-bar {
            background-color: #79c2d0;
        }

    }

    .business {
        position: $absolute;
        left: 67%;
        top: 29%;
        h5 {
            color: #82a6cc;
        }
        .business-bar {
            background-color: #82a6cc;
        }
    }

    .first {
        position: $absolute;
        left: 86%;
        top: 29%;
        h5 {
            color: #60a5ee;
        }
        .first-bar {
            background-color: #60a5ee;
        }
    }

    .flight-bar {
        position: $absolute;
        width: 3px;
        height: 50px;
        display: inline-block;
    }


}

.movie-title {
    color: $black;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.49px;
     white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 95%;
    line-height: 30px;

}

.movie-title-value {
    color: #4e5d78;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.46px;
    line-height: 20px;
}

.item {
    position: $relative;
    padding-top: 20px;
    display: inline-block;
}

.notify-badge {
    position: $absolute;
    left: 5px;
    height: 27px;
    width: 22px;
    right: -20px;
    top: 10px;
    z-index: 1;
    text-align: center;
    border-radius: 30px 30px 30px 30px;
    color: white;
    padding: 5px 10px;
    font-size: 20px;
}

.parent {
    position: $relative;
    margin-right: 10px;

}

.poster {
    //position: $relative;
    // width: 100%;
    height: 220px;
    width: 180px;
    // background-position: center center;
     background-size: contain;
     background-repeat: no-repeat;
    // object-fit: contain;
    // max-width: 100%;
    // max-height: 100%;
    // width: auto;
    //height: auto;
}

.ribbon {
    position: $absolute;
    top: -15px;
    left: -25px;
    height: 55px;
    width: 75px;
    z-index: 1;

}

.rank {
    position: $absolute;
    top: 0px;
    left: 7px;
    height: 25px;
    width: 24;
    z-index: 1;
    color: $white;
    font-family: "Inter Semibold", sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.26px;

}

.chartjs-size-monitor {
    height:100%;
}