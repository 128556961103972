.slick-dots {
	display: flex;
	justify-content: center;
	list-style-type: none;
	bottom: 0px;
}

.slick-dots button {
	text-indent: -9999px;
	/*pointer-events: none;*/
}


.slick-dots li {
	background-color: transparent;
	border-radius: 11px;
	height: 10px !important;
	width: 10px !important;
	border: 1px solid #e0e0e0;
	cursor: pointer;
	transition: all .4s ease-in-out;
}

/*.slick-dots li:hover {*/
/*	background-color: blue;*/
/*}*/

.slick-dots li.slick-active{
	background-color: #e0e0e0;
}
.carousel {
	height: 30px;
	width: 100%;
	margin-left: -16px;
	position: relative;
}

.carousel::-webkit-scrollbar {
	height: 0;
}

.carousel-arrow {
	position: absolute;
	top: 104px;
	height: 40px;
	width: 40px;
	background-color: rgba(255, 255, 255, 0.8);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
	border-radius: 50%;
	border: 3px solid #4091D7;
	color: #4091D7;
	font-size: 24px;
	font-weight: 700;
	line-height: 40px;
	text-align: center;
	z-index: 1;
	transition: all .6s ease-in-out;
	opacity: 1;
	box-sizing: content-box;
}

.carousel-arrow:hover {
	background-color: rgba(255, 255, 255, 1);
}

.carousel-arrow.hide {
	opacity: 0;
	transform: scale(0);
	cursor: default;
}

.carousel-arrow.show {
	opacity: 1;
	transform: scale(1);
	cursor: pointer;
}

.carousel-arrow.right {
	right: 8px;
}

.carousel-arrow.left {
	left: 32px;
}

.carousel-item {
	height: 320px;
	/*width: 167px;*/
	display: initial;
}

.carousel-flag-wrapper {
	width: 40px;
}

.carousel-flag {
	position: absolute;
	top: 0;
}

.carousel-flag-number {
	width: 37px;
	height: 35px;
	position: absolute;
	top: 0;
	color: white;
	font-weight: 600;
	line-height: 37px;
	text-align: center;
}

.carousel-image-wrapper {
	position: relative;
	height: 248px;
	width: 167px;
	border: 1px solid #e3e3e3;
}

.carousel-image-wrapper.audio, .carousel-image-wrapper.game, .carousel-image-wrapper.livetv{
	margin-top: 50px;
	height: 167px;
}

.carousel-alt-title {
	position: absolute;
	top: 55%;
	font-size: 18px;
	width: 167px;
	color: #b4d0ed;
	text-align: center;
	font-weight: 700;
	word-wrap: break-word;
	text-overflow: ellipsis;
 	overflow: hidden;
 	height: 45%;
}

.innovations .carousel-placeholder-image {
	    top: 35%;
    left: 33%;
}
.carousel-placeholder-image {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-100%);
}

.carousel-item-image {
	border: none;
	position: absolute;
	display: block;
	object-fit: cover;
	height: 100%;
	width: 167px;
}

.innovations .carousel-item-image {
	width: 100px;
	height: 45%
}

.title-data-wrapper {
	    width: 170px;
    height: 190px;
    margin-top: 15px;
    margin-left: 10px;
    background-color: whitesmoke;
    display: flex;
     flex-direction: column; 
    align-items: flex-end;
    padding-right: 10px
}

.title-data-values {
	    flex-grow: 1;
    display: flex;
    flex-direction: column;
    /*margin-right: 35px;*/
    color: darkslategray;
    margin-top: 20px;
    font-size: 31px;
    font-weight: bold;
    align-items: center;

}

.trend, .trend i {
	font-size: 15px;
	padding-top: 10px;
}
.down-trend {
	color: red;
}

.up-trend {
	color: green;
}

.data-title {
	color: gray;
	font-size: 14px;
	padding-bottom: 12px;
}

.carousel-item-title {
	font-family: 'Inter Bold', sans-serif;
	margin-top: 8px;
	font-size: 15px;
	line-height: 20px;
	text-overflow: ellipsis;
	white-space: nowrap; 
	overflow: hidden;
	width: 90%;
}

.innovations .carousel-item-title {
	padding-bottom: 20px;
	color: darkslategray;
}

.carousel-item-data {
	margin-top: 4px;
	font-size: 14px;
	line-height: 20px;
}

.carousel-item-change {
	font-size: 14px;
	line-height: 20px;
}


/*.carousel-pagination {
	height: 10px;
	position: absolute;
	bottom: 0;
	left: 50%;
	display: grid;
	grid-gap: 8px;
	grid-auto-flow: column;
}

.carousel-page {
	background-color: transparent;
	border-radius: 10px;
	height: 10px;
	width: 10px;
	border: 1px solid #00539d;
	cursor: pointer;
	transition: all .4s ease-in-out;
}

.carousel-page.active {
	background-color: #00539d;	
}*/