@charset "UTF-8";
/* ----------- Flex ----------------*/
/* ----------- Flex ----------------*/
/* ----------- Alignment ------------ */
/* ----------- Alignment ------------ */
/* ------------ Size ----------------*/
/* ------------ Size ----------------*/
/* ----------- Colors --------------*/
.side-bar.collapsed {
  padding-right: 0px;
  transition: padding-right 0.15s ease; }

.side-bar {
  position: static;
  padding-right: 0px;
  transition: padding-right 0.5s ease; }
  .side-bar > div > div > div > div {
    top: 21%; }

.not-clickable {
  pointer-events: none; }

.sidebar.collapsed {
  -webkit-flex: 0 0 28px;
          flex: 0 0 28px; }

.sidebar {
  background-color: #f3f5f7;
  width: 248px;
  -webkit-flex: 0 0 248px;
          flex: 0 0 248px;
  border-right: 1px solid #c3d4e3;
  font-family: 'Robot regular', sans-serif;
  padding: 24px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  margin-left: 64px;
  transition: all .25s ease-in-out; }
  .sidebar .app-name {
    font-family: "Raleway Extrabold", sans-serif;
    font-size: 20px;
    line-height: 19px;
    color: #112457;
    padding-bottom: 18px;
    margin: 0px 0px 16px;
    width: 195px;
    border-bottom: 1px solid #c3d4e3; }
  .sidebar .sidebar-expand {
    display: none; }
  .sidebar .sidebar-expand:first-child {
    display: inline;
    display: initial;
    position: absolute;
    top: 0;
    left: -7px; }
  .sidebar .sidebar-collapse {
    position: absolute;
    right: 10px;
    top: 3px; }
  .sidebar ul {
    margin-top: 20px;
    position: fixed;
    height: 100%; }
  .sidebar #accordion {
    overflow-y: auto; }
  .sidebar #accordion::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #0155b8; }
  .sidebar #accordion::-webkit-scrollbar {
    width: 0px;
    background-color: #0155b8; }
  .sidebar #accordion::-webkit-scrollbar-thumb {
    background-color: #0155b8; }
  .sidebar .menu-icon {
    width: 20px;
    height: 20px; }
  .sidebar .currentSection {
    margin-top: 0px;
    padding: 26px 30px;
    font-size: larger;
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
    color: #b2d7ff; }
  .sidebar li.nav-item:first-of-type .sidebar-menu-item:first-of-type:not(.level) {
    padding-top: 0; }
  .sidebar .sidebar-menu-item.level {
    padding-left: 40px;
    padding-top: 10px; }
    .sidebar .sidebar-menu-item.level a, .sidebar .sidebar-menu-item.level span {
      font-size: 14px; }
  .sidebar .sidebar-menu-item:not(.no-page) {
    cursor: pointer; }
  .sidebar .sidebar-menu-item {
    padding: 20px 0px 0; }
    .sidebar .sidebar-menu-item .sidebar-icon {
      margin-right: 10px; }
    .sidebar .sidebar-menu-item .accordion-link:after {
      font-family: "Font Awesome 5 free", sans-serif;
      content: "\F0D7";
      padding-right: 10px;
      font-weight: 900;
      margin-left: -15px; }
    .sidebar .sidebar-menu-item .accordion-link[aria-expanded="true"]:after {
      content: "\F0D8"; }
    .sidebar .sidebar-menu-item a.menu-selected {
      font-weight: 700; }
  .sidebar a,
  .sidebar span {
    color: #384f75;
    font-size: 1rem;
    letter-spacing: 0.3px;
    -webkit-align-items: center;
            align-items: center; }
  .sidebar a, .sidebar .menu-group {
    display: inline-block;
    width: 170px; }

/* ----------- Flex ----------------*/
/* ----------- Flex ----------------*/
/* ----------- Alignment ------------ */
/* ----------- Alignment ------------ */
/* ------------ Size ----------------*/
/* ------------ Size ----------------*/
/* ----------- Colors --------------*/
.card-body .yaxis-labels {
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 0.46px;
  list-style-type: none;
  padding-left: 5px;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  width: 10%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .card-body .yaxis-labels li {
    border-bottom: 1px solid #eeeeee;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    line-height: 30px; }
    .card-body .yaxis-labels li label {
      font-weight: bold;
      padding-right: 15px; }
  .card-body .yaxis-labels li:last-of-type {
    border-bottom: none; }
    .card-body .yaxis-labels li:last-of-type label {
      padding-right: 9px; }

.card-body .less-bar-count-labels li {
  height: 22%; }

.card-body .chartjs-render-monitor {
  max-width: 100%; }

/* ----------- Flex ----------------*/
/* ----------- Flex ----------------*/
/* ----------- Alignment ------------ */
/* ----------- Alignment ------------ */
/* ------------ Size ----------------*/
/* ------------ Size ----------------*/
/* ----------- Colors --------------*/
.Entertainment .chartjs-render-monitor, .line-chart .chartjs-render-monitor {
  max-width: inherit;
  min-height: 300px;
  max-height: 300px; }

.selector-label {
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: center;
          align-self: center;
  color: #000000;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.23px; }

.selector-row {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap; }

.line-chart {
  padding: 0 32px 32px 32px; }

.sub-title {
  width: 197px;
  height: 24px;
  color: #909090;
  font-family: "Inter regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.46px;
  line-height: 20px; }

.legend {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .legend .line {
    margin-left: 62px;
    margin-right: 8px;
    height: 1px;
    width: 24px;
    border: 1px solid #2a7fc9; }
    .legend .line.dashed {
      border-style: dashed;
      margin-left: 0; }
  .legend .key-label {
    width: 43px;
    height: 16px;
    color: #909090;
    font-family: "Inter regular", sans-serif;
    font-size: 12px;
    font-weight: 400;
    margin-right: 13px; }

/* ----------- Flex ----------------*/
/* ----------- Flex ----------------*/
/* ----------- Alignment ------------ */
/* ----------- Alignment ------------ */
/* ------------ Size ----------------*/
/* ------------ Size ----------------*/
/* ----------- Colors --------------*/
.chart-title {
  width: 300px;
  height: 24px;
  color: #000000;
  font-family: "Inter regular", sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.26px;
  margin-top: 24px;
  margin-left: 25px;
  margin-right: 25px; }
  .chart-title img {
    margin-left: 0; }
  .chart-title span {
    margin-left: 8px; }

.num-views {
  width: 182px;
  height: 16px;
  color: #000000;
  font-family: "Inter regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.27px;
  padding-left: 24px;
  margin-bottom: 15px; }

.line-chart {
  padding-left: 24px;
  padding-right: 32px; }
  .line-chart.small-left-padding {
    padding-left: 20px; }
  .line-chart .chartjs-render-monitor {
    height: 282px;
    color: #7d8696;
    font-family: "Inter regular", sans-serif;
    font-size: 16px;
    font-weight: 400; }
  .line-chart .chartjs-render-monitor-pop {
    height: 582px;
    color: #7d8696;
    font-family: "Inter regular", sans-serif;
    font-size: 16px;
    font-weight: 400; }

.card-body {
  padding: 24px;
  padding-bottom: 0; }
  .card-body.min-top {
    padding-top: 33px;
    margin-bottom: 15px; }

.chart-wrapper {
  max-height: 285px; }

.card-wrapper {
  max-height: 466px; }

.dropdown {
  padding-left: 21px; }
  .dropdown .btn {
    padding-left: 0; }

.vertical-bar-legend-token {
  display: inline-block;
  margin-right: 18px;
  margin-bottom: 25px;
  height: 22px;
  border-radius: 12px;
  color: white;
  font-size: 12px;
  line-height: 22px;
  text-align: center; }

.vertical-bar-legend-token.low {
  margin-left: 35px;
  background-color: #79C2D0;
  width: 50px; }

.vertical-bar-legend-token.med {
  background-color: #60A5EE;
  width: 72px; }

.vertical-bar-legend-token.high {
  background-color: #00539D;
  width: 52px; }

.Low {
  border-radius: 25px;
  background-color: #79c2d0;
  width: 50px;
  height: 22px;
  border: #a2d4ea;
  margin-left: 35px;
  margin-bottom: 25px;
  margin-right: 25px; }

.Low_label {
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.39px;
  line-height: 22px;
  -webkit-align-content: center;
          align-content: center;
  text-align: center;
  font-family: "Inter regular", sans-serif; }

.Medium {
  border-radius: 25px;
  background-color: #60a5ee;
  width: 72px;
  height: 18px;
  border: #afc5f0;
  margin-right: 25px; }

.High {
  border-radius: 25px;
  background-color: #00539d;
  width: 52px;
  height: 18px;
  border: #006cda; }

.completion_views {
  color: #7d8696; }

.completion_completion {
  color: #7d8696;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.23px; }

/* ----------- Flex ----------------*/
/* ----------- Flex ----------------*/
/* ----------- Alignment ------------ */
/* ----------- Alignment ------------ */
/* ------------ Size ----------------*/
/* ------------ Size ----------------*/
/* ----------- Colors --------------*/
.posters {
  width: 100%; }

.poster-label {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-left: -32px;
  margin-top: 10px;
  font-weight: bold; }

.slick-next:before,
.slick-prev:before {
  color: #2a7fc9; }

.slick-prev {
  left: 0; }

.slick-next {
  right: 0; }

.slick-list {
  margin-left: 40px;
  margin-right: 15px;
  height: 360px; }

.cabin-class {
  margin-top: 6%; }
  .cabin-class h6 {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.46px;
    line-height: 20px; }
  .cabin-class h5 {
    font-weight: bold; }
  .cabin-class .airplane {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto; }
  .cabin-class .economy {
    position: absolute;
    left: 20%;
    top: 30%; }
    .cabin-class .economy h5 {
      color: #00539d; }
    .cabin-class .economy .economy-bar {
      background-color: #00539d; }
  .cabin-class .p-economy {
    position: absolute;
    left: 49%;
    top: 29%; }
    .cabin-class .p-economy h5 {
      color: #79c2d0; }
    .cabin-class .p-economy .p-economy-bar {
      background-color: #79c2d0; }
  .cabin-class .business {
    position: absolute;
    left: 67%;
    top: 29%; }
    .cabin-class .business h5 {
      color: #82a6cc; }
    .cabin-class .business .business-bar {
      background-color: #82a6cc; }
  .cabin-class .first {
    position: absolute;
    left: 86%;
    top: 29%; }
    .cabin-class .first h5 {
      color: #60a5ee; }
    .cabin-class .first .first-bar {
      background-color: #60a5ee; }
  .cabin-class .flight-bar {
    position: absolute;
    width: 3px;
    height: 50px;
    display: inline-block; }

.movie-title {
  color: #000000;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.49px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 95%;
  line-height: 30px; }

.movie-title-value {
  color: #4e5d78;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.46px;
  line-height: 20px; }

.item {
  position: relative;
  padding-top: 20px;
  display: inline-block; }

.notify-badge {
  position: absolute;
  left: 5px;
  height: 27px;
  width: 22px;
  right: -20px;
  top: 10px;
  z-index: 1;
  text-align: center;
  border-radius: 30px 30px 30px 30px;
  color: white;
  padding: 5px 10px;
  font-size: 20px; }

.parent {
  position: relative;
  margin-right: 10px; }

.poster {
  height: 220px;
  width: 180px;
  background-size: contain;
  background-repeat: no-repeat; }

.ribbon {
  position: absolute;
  top: -15px;
  left: -25px;
  height: 55px;
  width: 75px;
  z-index: 1; }

.rank {
  position: absolute;
  top: 0px;
  left: 7px;
  height: 25px;
  width: 24;
  z-index: 1;
  color: #ffffff;
  font-family: "Inter Semibold", sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.26px; }

.chartjs-size-monitor {
  height: 100%; }

@font-face {
  font-family: 'Inter regular';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Inter-Regular.bffaed79.woff2) format("woff2"), url(/static/media/Inter-Regular.aebfbb3c.woff) format("woff"); }

@font-face {
  font-family: 'Inter Semibold';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Inter-SemiBold.cd04cacd.woff2) format("woff2"), url(/static/media/Inter-SemiBold.acc6eebd.woff) format("woff"); }

@font-face {
  font-family: 'Inter Bold';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Inter-Bold.34356f6b.woff2) format("woff2"), url(/static/media/Inter-Bold.61c493e3.woff) format("woff"); }

@font-face {
  font-family: 'Raleway Extrabold';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/raleway-extrabold-webfont.56d21be5.woff2) format("woff2"), url(/static/media/raleway-extrabold-webfont.2eb8e062.woff) format("woff"); }

@font-face {
  font-family: 'Robot regular';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/roboto-regular-webfont.7ba184e3.woff2) format("woff2"), url(/static/media/roboto-regular-webfont.2274f1da.woff) format("woff"); }

@font-face {
  font-family: 'Robot medium';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/roboto-medium-webfont.713e4ea0.woff2) format("woff2"), url(/static/media/roboto-medium-webfont.771880e8.woff) format("woff"); }

/* Inter UI Font */
@font-face {
  font-family: 'Inter-UI';
  font-weight: 400;
  src: url(/static/media/Inter-UI-Regular.2ac4e7b8.woff2) format("woff2"), url(/static/media/Inter-UI-Regular.da343883.woff) format("woff"); }

@font-face {
  font-family: 'Inter-UI';
  font-weight: 600;
  src: url(/static/media/Inter-UI-Medium.0159e50e.woff2) format("woff2"), url(/static/media/Inter-UI-Medium.b458e3cc.woff) format("woff"); }

@font-face {
  font-family: 'Inter-UI';
  font-weight: 700;
  src: url(/static/media/Inter-UI-Bold.c72d8321.woff2) format("woff2"), url(/static/media/Inter-UI-Bold.6372a8d9.woff) format("woff"); }

.view-more {
  line-height: 40px;
  position: absolute;
  right: 24px;
  top: 2px;
  font-size: 14px;
  color: #2A7FC9;
  text-transform: capitalize; }
  .view-more span, .view-more a {
    color: #2A7FC9;
    text-decoration: none; }

.header .page-title {
  padding-left: 0; }

.components-wrapper .disable-date {
  display: none;
  width: 280px;
  position: absolute;
  z-index: 999;
  height: 50px;
  right: 440px;
  background-color: #ccc;
  opacity: .6;
  top: -88px; }

#paginator {
  display: -webkit-flex;
  display: flex;
  font-family: 'Inter regular', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 10px; }
  #paginator span {
    padding: 6px 0 0 5px; }
  #paginator span:first-child {
    padding: 6px 5px 0 0; }
  #paginator span.total-pages {
    padding-left: 5px; }
  #paginator .arrows-buttons {
    margin: 7px 0 0 20px;
    display: -webkit-flex;
    display: flex;
    position: relative;
    cursor: pointer; }
    #paginator .arrows-buttons i {
      -webkit-transform: scale(1.22, 0.9);
              transform: scale(1.22, 0.9); }
      #paginator .arrows-buttons i:first-child {
        margin-right: 25px; }
      #paginator .arrows-buttons i.disabled {
        opacity: .3; }
    #paginator .arrows-buttons div.disable-cover {
      position: absolute;
      z-index: 999;
      width: 22px;
      height: 18px;
      left: -3px; }
      #paginator .arrows-buttons div.disable-cover.forward {
        right: -3px;
        left: auto;
        left: initial; }
  #paginator .workbench-select-container {
    margin: 0;
    color: #000;
    font-size: 16px; }
    #paginator .workbench-select-container .workbench-select__control {
      width: 60px; }
    #paginator .workbench-select-container .workbench-select__indicator-separator {
      display: none; }
    #paginator .workbench-select-container .workbench-select__dropdown-indicator {
      padding: 2px;
      width: 20px; }
      #paginator .workbench-select-container .workbench-select__dropdown-indicator::before {
        font-family: "Font Awesome 5 Free", sans-serif;
        font-weight: 900;
        content: "\F0DD";
        margin-top: -4px; }
      #paginator .workbench-select-container .workbench-select__dropdown-indicator svg {
        display: none; }
    #paginator .workbench-select-container .workbench-select__menu {
      width: 70px !important; }
      #paginator .workbench-select-container .workbench-select__menu .workbench-select__menu-notice--no-options {
        font-size: 12px;
        color: black; }
  #paginator .total-pages {
    margin-right: 15px; }

.rdg-selected {
  border: none !important; }

div.react-grid-Container .react-grid-Main {
  outline: none;
  box-shadow: 0 1px 2px #cfc9c9;
  border-radius: 3px; }
  div.react-grid-Container .react-grid-Main .react-grid-Grid {
    border: none; }
    div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header {
      font-size: 16px;
      font-family: 'Inter regular', sans-serif;
      background-color: #fff;
      overflow: hidden; }
      div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow {
        width: 100% !important; }
        div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell {
          background-color: #fff;
          height: 24px;
          color: #000000;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          text-transform: capitalize;
          border: none;
          cursor: pointer;
          padding-right: 10px;
          padding-top: 12px; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .active-sort {
            color: #2A7FC9; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .pull-right {
            display: none; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .fa.fa-sort {
            color: #e4e4e4;
            font-size: 17px;
            margin-left: 3px; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .fa.active {
            position: absolute;
            z-index: 0;
            color: #2a7fc9;
            margin-left: -10px;
            font-size: 17px; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .fa.fa-sort-up {
            top: 15px;
            visibility: hidden; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .fa.fa-sort-down {
            top: 15px;
            visibility: hidden; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .fa.fa-sort-down.first-render {
            visibility: visible; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .react-grid-HeaderCell-sortable--ascending .header-label {
            color: #2A7FC9; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .react-grid-HeaderCell-sortable--ascending .fa.fa-sort-down {
            visibility: hidden; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .react-grid-HeaderCell-sortable--ascending .fa.fa-sort-up {
            visibility: visible; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .react-grid-HeaderCell-sortable--descending .header-label {
            color: #2A7FC9; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .react-grid-HeaderCell-sortable--descending .fa.fa-sort-down {
            visibility: visible; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .react-grid-HeaderCell-sortable--descending .fa.fa-sort-up {
            visibility: hidden; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .react-grid-HeaderCell__draggable {
            width: 3px !important;
            margin: 0 1px 0 1px;
            background-color: #ededed; }
    div.react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Viewport .react-grid-Canvas {
      overflow-x: auto !important;
      overflow-y: hidden !important;
      z-index: 0;
      background-color: #fff; }
      div.react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Viewport .react-grid-Canvas > div:last-child {
        border-bottom: 1px #ededed solid; }
      div.react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Viewport .react-grid-Canvas > div .react-grid-Row {
        height: 48px; }
        div.react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Viewport .react-grid-Canvas > div .react-grid-Row:last-child {
          border-bottom: 1px #000 solid; }
        div.react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Viewport .react-grid-Canvas > div .react-grid-Row.react-grid-Row--even .react-grid-Cell {
          background-color: #ededed; }
        div.react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Viewport .react-grid-Canvas > div .react-grid-Row.react-grid-Row--odd .react-grid-Cell {
          background-color: #fff; }
        div.react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Viewport .react-grid-Canvas > div .react-grid-Row div.react-grid-Cell {
          font-size: 16px;
          border: none; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Viewport .react-grid-Canvas > div .react-grid-Row div.react-grid-Cell.rdg-last--frozen {
            box-shadow: none; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Viewport .react-grid-Canvas > div .react-grid-Row div.react-grid-Cell .react-grid-Cell__value div {
            width: 95%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }
            div.react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Viewport .react-grid-Canvas > div .react-grid-Row div.react-grid-Cell .react-grid-Cell__value div .grid-link {
              color: #2A7FC9;
              letter-spacing: .45px;
              cursor: pointer; }

/******** Your Insights ******/
.card-container {
  width: 1080px; }
  .card-container .slick-arrow {
    position: absolute;
    top: 145px;
    height: 40px;
    width: 40px;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    border: 3px solid #4091D7;
    /* color: #4091D7; */
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    z-index: 1;
    transition: all .6s ease-in-out;
    opacity: 1;
    box-sizing: content-box; }
  .card-container .slick-slider {
    left: -40px; }
  .card-container .slick-prev {
    left: 42px; }
  .card-container .slick-prev:before {
    content: '\2039';
    font-family: 'Inter Semibold', sans-serif; }
  .card-container .slick-next:before {
    content: '\203A';
    font-family: 'Inter Semibold', sans-serif; }

.optimize-content {
  color: white;
  position: absolute;
  top: 125px;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  font-weight: bold;
  font-size: 25px; }

div.card-layout {
  width: 225px !important;
  position: relative;
  height: 296px;
  box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background-color: #ffffff; }

div.card-layout:not(:last-child) {
  margin-right: 26px; }

.optimization-poster {
  background-position: left top, right top;
  background-size: 120px 190px;
  background-repeat: no-repeat;
  width: 225px;
  height: 190px;
  background-color: #2a7fc9;
  /* left: 10px; */
  margin-left: -1px; }

.card-overlay {
  position: absolute;
  height: 190px;
  width: 225px;
  opacity: 0.5;
  background: black;
  top: 55px;
  left: 0px; }

.optimize-count {
  height: 63px;
  color: #ffffff;
  font-family: 'Inter Semibold', sans-serif;
  font-size: 88px;
  font-weight: 600; }

.optimize-subtext {
  width: 239px;
  height: 24px;
  color: #ffffff;
  font-family: 'Inter Regular', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center; }

.optimize-link {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  height: 24px;
  color: #2264a0;
  font-family: "Inter Regular", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 10px; }

.optimization img {
  object-fit: none;
  height: 177px;
  -webkit-filter: blur(1.5px);
          filter: blur(1.5px); }

.manage-media .modal-content {
  width: 700px;
  top: -150px;
  height: 370px; }

/******** Your Insights ******/
.innoviation-title {
  margin-left: 64px;
  margin-top: 24px;
  width: 472px;
  height: 32px;
  color: #000000;
  font-family: 'Inter regular', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px; }

.innovation-aggregate-score {
  width: 248px;
  height: 20px;
  color: #000000;
  font-family: 'Inter regular', sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-top: 3%; }

.innovation-aggreate-currentscore {
  width: 79px;
  height: 78px;
  color: #000000;
  font-family: 'Inter regular', sans-serif;
  font-size: 64px;
  font-weight: 700; }

.innovation-aggreate-currentotal {
  width: 76px;
  height: 39px;
  color: #a2a2a2;
  font-family: 'Inter regular', sans-serif;
  font-size: 32px;
  font-weight: 400;
  margin-right: 15px; }

.innovation-reddown {
  width: 18px;
  height: 26px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.innovation-downgradevalue {
  width: 47px;
  height: 42px;
  color: #d24646;
  font-family: 'Inter regular', sans-serif;
  font-size: 36px;
  font-weight: 700; }

.fa-arrow-down {
  font-size: 35px;
  color: red; }

.fa-arrow-up:before, .fa-arrow-down:before {
  padding-right: 5px; }

.fa-arrow-up {
  font-size: 35px;
  color: green; }

.innovation-trend-performance {
  color: #000000;
  font-family: "Inter Semibold", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  margin-top: 27px; }

.flipchart {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1); }

.acronym {
  font-size: 11px;
  font-weight: bold;
  color: gray;
  width: 232px;
  padding-bottom: 10px; }

.flip-horizontal, .x-grid-cell-inner, .x-column-header-text, .x-panel-header-text {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph;
  /*IE*/
  -webkit-filter: fliph;
          filter: fliph;
  /*IE*/ }

.x-column-header-text, .x-panel-header-text {
  display: inline-block; }

.innovation-card-trend {
  border-radius: 2px;
  background-color: #ffffff; }

.innovation-feedback {
  width: 129px;
  height: 20px;
  color: #000000;
  font-family: 'Inter regular', sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-left: 252px;
  margin-right: 27px; }

.innovation-trend-image {
  width: 167px;
  height: 270px;
  padding-bottom: 7px;
  padding-top: 24px; }

.manage-detail .modal-content {
  max-width: 871px;
  min-width: 871px;
  min-height: 400px;
  top: -230px;
  left: -130px;
  border: none;
  box-shadow: 0 1px 8px #979797;
  border-radius: 10px;
  background-color: #ffffff; }
  .manage-detail .modal-content .modal-header {
    padding: 0;
    height: 0; }
    .manage-detail .modal-content .modal-header h5.modal-title {
      color: white; }
  .manage-detail .modal-content .close {
    font-size: 60px;
    font-weight: lighter;
    opacity: 0.7;
    margin-top: -30px;
    padding: 0;
    cursor: pointer;
    outline: none; }
  .manage-detail .modal-content .close:before, .manage-detail .modal-content .close:after {
    display: none; }
  .manage-detail .modal-content .carousel-placeholder-image {
    top: 27%;
    left: 12%; }

.custom-control.custom-switch {
  width: 0;
  height: 0;
  top: 100px;
  left: -20px;
  float: right;
  z-index: 1;
  padding-left: 0;
  min-height: 0; }

.valign-mid {
  vertical-align: middle; }

.page-header {
  color: #072457;
  font-size: 36px;
  font-family: 'Inter Bold', sans-serif;
  font-style: normal;
  letter-spacing: 0.45px;
  font-weight: bold;
  position: relative;
  top: -90px;
  left: 0px; }

.modal-header,
.modal-body,
.modal-footer {
  border: 0; }

.modal-body .linechart-pop-align {
  padding-top: 135px; }
  .modal-body .linechart-pop-align .min-top.card-body {
    padding-left: 0; }

.modal-backdrop {
  background-color: white; }

.modal-backdrop.show {
  opacity: 0.8; }

.modal-header h5 {
  font-family: "Inter Bold", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px; }

.modal-body {
  font-family: "Inter Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 24px; }

.modal-content .primary.btn-secondary {
  background-color: #2a7fc9;
  border: 1px solid #2a7fc9; }

.main-content {
  background-color: #f9f9f9;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  overflow-x: hidden;
  padding: 0 48px 48px 48px; }
  .main-content .col {
    padding-left: 1;
    padding-right: 0; }
  .main-content .card {
    box-shadow: 0 1px 2px #cfc9c9;
    min-width: 1032px;
    max-width: 1032px;
    border: none;
    height: 550px; }
  .main-content .card.bar-chart {
    min-width: 504px;
    max-width: 504px; }
  .main-content .small-bar-chart {
    height: 316px;
    min-width: 504px;
    max-width: 504px; }
  .main-content .bar-chart-gap {
    margin-right: 24px; }
  .main-content .align-component {
    width: 1032px;
    margin-bottom: 26px; }
  .main-content .components-wrapper {
    height: 100%;
    margin-right: 2px; }
  .main-content .header {
    background-color: #f9f9f9;
    position: relative;
    display: -webkit-flex;
    display: flex;
    max-width: 1032px;
    padding-left: 0px;
    padding-top: 13px;
    -webkit-align-items: center;
            align-items: center;
    height: 107px; }
  .main-content .selector-row {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    margin-top: -50px; }
  .main-content .selector-label {
    display: -webkit-flex;
    display: flex;
    -webkit-align-self: center;
            align-self: center;
    color: #000000;
    font-size: 14px;
    letter-spacing: 0.23px;
    padding-right: 48px; }
  .main-content .page-title {
    color: #072457;
    font-size: 36px;
    font-family: 'Inter Bold', sans-serif;
    font-style: normal;
    letter-spacing: 0.45px;
    width: 30vw;
    float: left;
    padding-left: 0;
    margin-top: -50px; }
  .main-content .quick-sight {
    letter-spacing: 0px;
    width: 30vw;
    float: left;
    padding-left: 0px;
    margin-top: 0px; }
  .main-content .component-title {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.45px; }
  .main-content .component-subtitle {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.45px; }
  .main-content .month-selector .select__control {
    width: 142px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #2a7fc9;
    background-color: #2a7fc9; }
    .main-content .month-selector .select__control .select__value-container {
      -webkit-justify-content: center;
              justify-content: center; }
      .main-content .month-selector .select__control .select__value-container .select__placeholder {
        color: white; }
  .main-content .month-selector .select__indicator-separator {
    display: none; }
  .main-content .month-selector .select__single-value {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.26px; }
  .main-content .month-selector .select__dropdown-indicator svg {
    fill: #ffffff; }

.card.mask {
  background-color: lightgray; }

.card.mask:before {
  content: 'Draft view';
  color: orange;
  font-size: 20px;
  text-align: center;
  margin-top: 10px; }

.container-fluid {
  padding: 0; }

.container-fluid.main-container .card-body h4 {
  font-size: 1rem;
  font-weight: bold; }

.container-fluid.main-container .row {
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  margin-left: 0;
  margin-right: 0; }

.container-fluid.main-container .mt-3 .row {
  min-width: 1032px;
  max-width: 1032px; }

.container-fluid.main-container .row.align-component .col:first-of-type {
  padding-right: 8px; }

.container-fluid.main-container .row.align-component .col:nth-of-type(2) {
  padding-left: 8px; }

div.link-arrow {
  margin: 0 0 0 5px;
  color: #2A7FC9;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.card-footer {
  background-color: transparent;
  padding: 1px 0 1px 0;
  height: 40px;
  position: absolute;
  bottom: 0;
  width: 100%; }
  .card-footer .nav-link {
    font-family: 'Inter regular', sans-serif;
    font-size: 14px;
    margin-right: 15px; }
    .card-footer .nav-link a {
      display: -webkit-flex;
      display: flex;
      color: #2a7fc9; }
  .card-footer .abs-right {
    position: absolute;
    right: 18px;
    top: 11px;
    padding: 0;
    display: -webkit-flex;
    display: flex; }
  .card-footer .dropdown .btn {
    color: #2a7fc9;
    font-family: 'Inter regular', sans-serif;
    font-size: 14px; }
    .card-footer .dropdown .btn .select__indicator-separator {
      display: none; }
  .card-footer .dropdown .dropdown-item {
    color: #2a7fc9;
    font-family: 'Inter regular', sans-serif;
    font-size: 14px; }

.fixed {
  position: fixed; }

.footer {
  background-color: transparent; }

/*------- Dark theme ---------*/
.dark .logo-wrapper {
  background-color: black; }

.dark #sidebar {
  background-color: #181818; }
  .dark #sidebar .menu-item1 {
    background-color: #181818; }
  .dark #sidebar .menu-item2 {
    background-color: #101010; }

.dark .main-content {
  background-color: black; }
  .dark .main-content .select__control {
    background-color: black; }
  .dark .main-content .select__menu {
    background-color: black; }
    .dark .main-content .select__menu .select__option--is-focused {
      background-color: #181818; }
  .dark .main-content .card {
    background-color: #181818;
    color: white; }
  .dark .main-content .basic-multi-select {
    background-color: #181818;
    color: white; }

.dark .navbar {
  background-color: black;
  color: white; }

@-webkit-keyframes blink {
  0% {
    opacity: .2; }
  20% {
    opacity: 1; }
  100% {
    opacity: .2; } }

@keyframes blink {
  0% {
    opacity: .2; }
  20% {
    opacity: 1; }
  100% {
    opacity: .2; } }

.loading span {
  -webkit-animation-name: blink;
          animation-name: blink;
  -webkit-animation-duration: 1.4s;
          animation-duration: 1.4s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.loading span:nth-child(2) {
  -webkit-animation-delay: .2s;
          animation-delay: .2s; }

.loading span:nth-child(3) {
  -webkit-animation-delay: .4s;
          animation-delay: .4s; }

.current-user-photo {
  margin-left: -10px;
  margin-top: -10px;
  -webkit-clip-path: circle(17px at center);
          clip-path: circle(17px at center); }

/* ----------- Flex ----------------*/
/* ----------- Flex ----------------*/
/* ----------- Alignment ------------ */
/* ----------- Alignment ------------ */
/* ------------ Size ----------------*/
/* ------------ Size ----------------*/
/* ----------- Colors --------------*/
.airlinesummary {
  height: 122px;
  box-shadow: 0 1px 2px #cfc9c9;
  border-radius: 3px;
  background-color: #ffffff;
  margin-left: 0;
  margin-right: 0; }
  .airlinesummary .col {
    max-width: 20%; }

.clickable {
  cursor: pointer; }

.coverage-icon {
  margin-right: 7px; }

.airlinesummarycard {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-right: 24px;
  font-family: "Inter regular", sans-serif;
  min-width: 130px;
  margin-top: 6px;
  width: 176px; }
  .airlinesummarycard .cardtitle {
    color: #7d8696;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.26px; }
  .airlinesummarycard .percent-title {
    width: 197px;
    height: 24px;
    color: #ea4335;
    font-family: "Inter regular", sans-serif;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.53px;
    line-height: 20px; }
  .airlinesummarycard .carddata {
    width: 176px;
    height: 48px;
    color: #000000;
    font-family: "Inter regular", sans-serif;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0.46px; }
  .airlinesummarycard .carddata-smallfont {
    width: 120px;
    height: 48px;
    color: #000000;
    font-family: "Inter regular", sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.46px; }
  .airlinesummarycard .carddatausage {
    color: #000000;
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 0.38px;
    font-family: 'Inter Semibold', sans-serif;
    display: inline-block;
    white-space: nowrap; }
  .airlinesummarycard .cardusage {
    height: 20px;
    color: #2A7FC9;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.46px;
    line-height: 20px; }
  .airlinesummarycard .selected-section {
    font-weight: bold; }
  .airlinesummarycard .selected-tab {
    width: 100%;
    height: 7px;
    background-color: #2A7FC9;
    position: relative;
    top: 8px; }

.Entertainment {
  border-radius: 3px;
  background-color: #ffffff;
  padding-top: 5px;
  width: 1032px; }
  .Entertainment .tabs-wrapper {
    margin-bottom: 18px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 8px; }
  .Entertainment .card-body {
    height: 361px;
    padding-right: 6px;
    padding-left: 18px; }
  .Entertainment .airlinesummarycard:hover .tab {
    background-color: lightgray; }
  .Entertainment .airlinesummarycard {
    -webkit-align-items: start;
            align-items: start; }
    .Entertainment .airlinesummarycard .tab {
      width: 100%;
      height: 7px;
      background-color: #ffffff;
      position: relative;
      top: 8px; }
    .Entertainment .airlinesummarycard .cardtitle {
      color: #000000; }
  .Entertainment .box {
    margin: 0 3%;
    background-color: #ffffff;
    text-align: top;
    -webkit-align-items: top;
            align-items: top; }
  .Entertainment .Title {
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.29px; }
  .Entertainment .Entertainment-title {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.26px; }
  .Entertainment .Entertainment-data {
    color: #000000;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0.38px; }

.Movie-subtitle {
  color: #0065c8;
  font-size: 20px;
  padding: 0px 0px 0px 10px;
  letter-spacing: 0.3px; }

.builder-DateRangelable {
  padding: 10px;
  color: #000000;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.23px; }

.entertainment-overview-title {
  margin-bottom: 20px;
  color: #000000;
  font-weight: 400;
  letter-spacing: 0.3px; }

.line {
  width: 1px;
  border: 1px solid #eeeeee;
  margin-top: 15px;
  margin-bottom: 15px; }

.entertainment-totalviewers {
  color: #7d8696;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.43px;
  line-height: 20px; }

.entertainment-totalviewersdata {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.29px;
  Margin-bottom: 15px; }

.entertainment-viewmore.card-footer {
  font-size: 13px;
  font-weight: 400;
  background-color: transparent;
  padding-right: 32px; }
  .entertainment-viewmore.card-footer a,
  .entertainment-viewmore.card-footer .select__single-value,
  .entertainment-viewmore.card-footer .select__dropdown-indicator {
    color: #2A7FC9; }

.entertainment-viewmore.text-left {
  width: 165px; }

.entertainment-viewmore.text-right {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center; }

.CardFooter {
  color: #ffffff; }

.hidden {
  visibility: hidden; }

.feedback-submit {
  width: 187px;
  height: 40px;
  border-radius: 2px;
  background-color: #2a7fc9;
  color: #ffffff;
  font-family: "Inter Semibold", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-left: 24px; }

.feedback-clear {
  width: 115px;
  height: 40px;
  background-color: #ffffff;
  border: 2px solid #2a7fc9;
  color: #2a7fc9;
  font-family: "Inter Semibold", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-left: 24px; }

textarea {
  width: 100%;
  -webkit-box-sizing: border-box; }

.feedback-submit-div {
  padding-top: 48px;
  margin-bottom: 24px;
  margin-right: 24px;
  float: right; }

.feedback-card {
  position: relative;
  width: 856px;
  height: 562px;
  box-shadow: 0 1px 2px #ececec;
  border-radius: 3px;
  background-color: #ffffff; }

.modal-dialog {
  margin-top: 25%;
  margin-left: 28%; }

.feedback-card-pop {
  width: 856px;
  height: 562px;
  box-shadow: 0 1px 2px #ececec;
  border-radius: 3px; }

.feedback-headertext {
  width: 639px;
  height: 32px;
  color: #000000;
  font-family: "Inter Semibold", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  padding-top: 18px;
  padding-left: 24px; }

.feedback-secondheadertext {
  width: 639px;
  height: 32px;
  color: #000000;
  font-family: "Inter Semibold", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding-top: 18px;
  padding-left: 24px; }

.feedback-stars {
  padding-top: 20px;
  padding-left: 26px; }

.feedback-textarea {
  width: 808px;
  height: 262px;
  border-radius: 7px;
  font-family: "Inter UI", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.35px;
  line-height: 22px;
  margin-left: 24px; }

.popup {
  position: absolute;
  width: 60%;
  height: 10%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0); }

.popup_inner {
  position: absolute;
  right: 25%;
  bottom: 25%;
  margin: auto;
  background: white; }

.popup-modal {
  position: absolute;
  width: 552px;
  height: 176px;
  box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  background-color: #ffffff; }

.popup_button {
  position: absolute;
  top: 0px;
  right: 0px; }

.popup-submit {
  width: 115px;
  height: 40px;
  border-radius: 2px;
  border: 2px solid #2a7fc9;
  background-color: #2a7fc9;
  color: #ffffff;
  font-family: "Inter Semibold", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-right: 15px;
  text-align: center;
  margin-top: 24px; }

.popup-clear {
  width: 115px;
  height: 40px;
  border-radius: 2px;
  border: 2px solid #2a7fc9;
  text-align: center;
  background-color: #ffffff;
  color: #2165a0;
  font-family: "Inter Semibold", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-right: 15px;
  margin-top: 24px; }

.modal-first {
  width: 552px;
  height: 176px;
  box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.2);
  border-radius: 2px; }

.modal-first-body {
  margin-right: 24px;
  float: right; }

.modal-pop-submit-feedback-text1 {
  width: 504px;
  height: 32px;
  color: #000000;
  font-family: "Inter regular", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-top: 24px; }

.modal-pop-submit-feedback-text2 {
  width: 504px;
  height: 24px;
  color: #000000;
  font-family: "Inter regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-top: 8px; }

.manage-detail .details {
  width: 167px; }

.trend-averageviewingtime {
  width: 100px;
  height: 64px;
  border-radius: 14px;
  background-color: #2a7fc9;
  margin-bottom: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  /* align-content: space-between; */
  /* align-items: center; */
  -webkit-justify-content: start;
          justify-content: start; }

.trend-averageviewingtime_headertext {
  /* Style for "Total View" */
  height: 24px;
  color: #ffffff;
  font-family: "Inter regular", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  padding-left: 12px;
  padding-top: 7px;
  padding-bottom: 27px;
  text-transform: capitalize; }

.trend-averageviewingtime_headervalue {
  /* Style for "354K hr" */
  height: 24px;
  color: #ffffff;
  font-family: "Inter regular", sans-serif;
  font-size: 24px;
  font-weight: 700;
  padding-left: 12px;
  letter-spacing: 0.31px; }
  .trend-averageviewingtime_headervalue .fas {
    font-size: 25px;
    color: white; }

.trend-takerate {
  width: 100px;
  height: 64px;
  border-radius: 14px;
  background-color: #79c2d0;
  margin-bottom: 12px; }

.trend_averagecompletion {
  width: 167px;
  height: 64px;
  border-radius: 14px;
  background-color: #60a5ee;
  margin-bottom: 12px; }

.trend-image {
  width: 167px;
  height: 248px;
  background-position: center;
  background-size: cover; }

.trend-image-title {
  width: 590px;
  height: 32px;
  color: #072457;
  font-family: "Inter regular", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px; }

.trend-performance {
  width: 185px;
  height: 24px;
  color: #000000;
  font-family: "Inter Semibold", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  margin-top: 27px; }
  .trend-performance .widget-image {
    margin-left: 0;
    margin-right: 5px; }

.trend-title-desc {
  width: 145px;
  height: 66px;
  color: #909090;
  font-family: "Inter regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.51px;
  line-height: 22px;
  padding-bottom: 17px; }

.ondeck {
  width: 145px;
  color: black;
  font-family: "Inter Semibold", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.51px;
  line-height: 21px; }

.ondeck-text {
  color: #909090;
  font-family: "Inter regular", sans-serif;
  font-size: 16px;
  font-weight: 400; }

.card-trend {
  width: 1024px;
  height: 482px;
  box-shadow: 0 1px 8px #979797;
  border-radius: 10px;
  background-color: #ffffff; }

.trend-col {
  -webkit-flex-grow: 0;
          flex-grow: 0;
  margin-right: 24px; }

.trend-line {
  width: 600px;
  background-color: #e0e0e0;
  margin-left: 0px;
  margin-bottom: 30px; }

.popup {
  width: 100%;
  height: 100%;
  margin-left: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10; }

.popup-shade {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  border-radius: 20px;
  background: white; }

.linechart-pop-align {
  padding-top: 125px;
  float: right;
  max-height: 500px;
  max-width: 500px;
  width: 100%;
  margin-left: -30px; }

.pop-totalviewers {
  width: 117px;
  height: 24px;
  color: #909090;
  font-family: "Inter regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px; }

.pop-totalviewers-value {
  width: 153px;
  height: 28px;
  color: #000000;
  font-family: "Inter regular", sans-serif;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.31px;
  padding-top: 7px; }

.close {
  position: absolute;
  z-index: 999;
  outline: none;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3; }

.close:hover {
  opacity: 1; }

.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333; }

.close:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.close:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }

#market-place .card-body {
  padding-left: 0;
  padding-right: 0; }

#market-place .widget-frame {
  height: 316px; }

#market-place .chart-wrapper,
#market-place .widget-title {
  margin-left: 32px; }

#market-place .Entertainment {
  position: relative;
  background-color: white;
  /*max-width: 1032px;*/
  height: 550px;
  box-shadow: 0 1px 2px #cfc9c9;
  border-radius: 3px;
  grid-column: span 2;
  transition: all .6s ease-in-out; }

#market-place .tabs-wrapper {
  margin: auto !important; }
  #market-place .tabs-wrapper .col {
    max-width: 175px; }
  #market-place .tabs-wrapper .col:nth-child(4) {
    max-width: 253px; }
    #market-place .tabs-wrapper .col:nth-child(4) .airlinesummarycard {
      max-width: 250px; }
  #market-place .tabs-wrapper .airlinesummarycard {
    margin-right: 0;
    width: auto;
    max-width: 160px; }
    #market-place .tabs-wrapper .airlinesummarycard .carddatausage {
      font-size: 1.9rem; }

.slick-dots {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	list-style-type: none;
	bottom: 0px;
}

.slick-dots button {
	text-indent: -9999px;
	/*pointer-events: none;*/
}


.slick-dots li {
	background-color: transparent;
	border-radius: 11px;
	height: 10px !important;
	width: 10px !important;
	border: 1px solid #e0e0e0;
	cursor: pointer;
	transition: all .4s ease-in-out;
}

/*.slick-dots li:hover {*/
/*	background-color: blue;*/
/*}*/

.slick-dots li.slick-active{
	background-color: #e0e0e0;
}
.carousel {
	height: 30px;
	width: 100%;
	margin-left: -16px;
	position: relative;
}

.carousel::-webkit-scrollbar {
	height: 0;
}

.carousel-arrow {
	position: absolute;
	top: 104px;
	height: 40px;
	width: 40px;
	background-color: rgba(255, 255, 255, 0.8);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
	border-radius: 50%;
	border: 3px solid #4091D7;
	color: #4091D7;
	font-size: 24px;
	font-weight: 700;
	line-height: 40px;
	text-align: center;
	z-index: 1;
	transition: all .6s ease-in-out;
	opacity: 1;
	box-sizing: content-box;
}

.carousel-arrow:hover {
	background-color: rgba(255, 255, 255, 1);
}

.carousel-arrow.hide {
	opacity: 0;
	-webkit-transform: scale(0);
	        transform: scale(0);
	cursor: default;
}

.carousel-arrow.show {
	opacity: 1;
	-webkit-transform: scale(1);
	        transform: scale(1);
	cursor: pointer;
}

.carousel-arrow.right {
	right: 8px;
}

.carousel-arrow.left {
	left: 32px;
}

.carousel-item {
	height: 320px;
	/*width: 167px;*/
	display: inline;
	display: initial;
}

.carousel-flag-wrapper {
	width: 40px;
}

.carousel-flag {
	position: absolute;
	top: 0;
}

.carousel-flag-number {
	width: 37px;
	height: 35px;
	position: absolute;
	top: 0;
	color: white;
	font-weight: 600;
	line-height: 37px;
	text-align: center;
}

.carousel-image-wrapper {
	position: relative;
	height: 248px;
	width: 167px;
	border: 1px solid #e3e3e3;
}

.carousel-image-wrapper.audio, .carousel-image-wrapper.game, .carousel-image-wrapper.livetv{
	margin-top: 50px;
	height: 167px;
}

.carousel-alt-title {
	position: absolute;
	top: 55%;
	font-size: 18px;
	width: 167px;
	color: #b4d0ed;
	text-align: center;
	font-weight: 700;
	word-wrap: break-word;
	text-overflow: ellipsis;
 	overflow: hidden;
 	height: 45%;
}

.innovations .carousel-placeholder-image {
	    top: 35%;
    left: 33%;
}
.carousel-placeholder-image {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%,-100%);
	        transform: translate(-50%,-100%);
}

.carousel-item-image {
	border: none;
	position: absolute;
	display: block;
	object-fit: cover;
	height: 100%;
	width: 167px;
}

.innovations .carousel-item-image {
	width: 100px;
	height: 45%
}

.title-data-wrapper {
	    width: 170px;
    height: 190px;
    margin-top: 15px;
    margin-left: 10px;
    background-color: whitesmoke;
    display: -webkit-flex;
    display: flex;
     -webkit-flex-direction: column;
             flex-direction: column; 
    -webkit-align-items: flex-end; 
            align-items: flex-end;
    padding-right: 10px
}

.title-data-values {
	    -webkit-flex-grow: 1;
	            flex-grow: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    /*margin-right: 35px;*/
    color: darkslategray;
    margin-top: 20px;
    font-size: 31px;
    font-weight: bold;
    -webkit-align-items: center;
            align-items: center;

}

.trend, .trend i {
	font-size: 15px;
	padding-top: 10px;
}
.down-trend {
	color: red;
}

.up-trend {
	color: green;
}

.data-title {
	color: gray;
	font-size: 14px;
	padding-bottom: 12px;
}

.carousel-item-title {
	font-family: 'Inter Bold', sans-serif;
	margin-top: 8px;
	font-size: 15px;
	line-height: 20px;
	text-overflow: ellipsis;
	white-space: nowrap; 
	overflow: hidden;
	width: 90%;
}

.innovations .carousel-item-title {
	padding-bottom: 20px;
	color: darkslategray;
}

.carousel-item-data {
	margin-top: 4px;
	font-size: 14px;
	line-height: 20px;
}

.carousel-item-change {
	font-size: 14px;
	line-height: 20px;
}


/*.carousel-pagination {
	height: 10px;
	position: absolute;
	bottom: 0;
	left: 50%;
	display: grid;
	grid-gap: 8px;
	grid-auto-flow: column;
}

.carousel-page {
	background-color: transparent;
	border-radius: 10px;
	height: 10px;
	width: 10px;
	border: 1px solid #00539d;
	cursor: pointer;
	transition: all .4s ease-in-out;
}

.carousel-page.active {
	background-color: #00539d;	
}*/
.coverage-widget {
	height: 171px;
	grid-column: span 2;
	width: 1032px;
}

.coverage-header {
	font-size: 24px;
	font-weight: 700;
	line-height: 48px;
}

.coverage-body {
	display: grid;
	grid-gap: 24px;
	grid-auto-flow: column;
}

.coverage-token {
	position: relative;
	height: 122px;
	width: 187px;
}

.coverage-token-image-wrapper {
	position: absolute;
	left: 12px;
	height:48px;
	width: 48px;
	border-radius: 8px;
}

.coverage-token-image {
	position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.coverage-token-background {
	bottom: 0;
	position: absolute;
	height: 110px;
	width: 187px;
	background-color: white;
	border-radius: 3px;
   box-shadow: 0 1px 2px #cfc9c9;
}

.coverage-token-data {
	position: absolute;
	left: 72px;
	top: 12px;
	height: 48px;
	width: calc(100% - 72px);
	font-size: 36px;
}

.coverage-token-title {
	position: absolute;
	left: 72px;
	top: 60px;
	font-size: 16px;
	width: calc(100% - 72px);
	height: 26px;
}
.horizontal-bar-chart-wrapper {
	width: 100%;
}

.horizontal-bar {
	font-size: 14px;
	width: calc(100% - 88px);
	margin-left: 32px;
	padding-bottom: 32px;
	empty-cells: hide;
	border-collapse:collapse;
}

.horizontal-bar th { 
	font-weight: 400
}

.horizontal-bar td { 
	border-bottom: 1px solid #EEE;
}

.horizontal-bar-chart {
	display: inline-block;
	width: calc(50% - 32px);
	background-color: green;
}

.item-number {
	font-weight: 600;
	line-height: 32px;
    min-width: 20px;
}

.item-title {
	max-width: 140px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 32px;
}


.item-title .item-title-tooltip {
	visibility: hidden;
	background-color: #fff;
	color: #909090;
	text-align: center;
	border-radius: 6px;
	padding: 5px 16px;
	position: absolute;
	z-index: 1;
	left: 0px;
	margin-left: 32px;
	margin-top: 32px;
	box-shadow: 0 1px 8px #979797;
}

.item-title .item-title-tooltip::before {
	content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGMAAAAvCAYAAAD3oXSCAAAAyElEQVR42u3ZwQ2AMBADwfRfwLULLwrggVj71hIFOINIuJxjjDHmq8zM5SpAIJ7H1YBACAKDEAQGIQgMQhAYhCA/QLiHwCAEgUEIAoMQBAYhCAxCEBiEIDAIQWAQgsAgBIFBCAKDEAQGIQgMQhAYxHoQaul1IPSya0BSStaDpJWrBUktVQeSXqYGpOWtigdp+97GgrSeROJA2s/oMSBb/l7xINvmOliQrRNPHMj2uwAMiJcyEBAhICBCQECEgIAIUT46McaYd7kBY4JIdzs54KEAAAAASUVORK5CYII=);
    position: absolute;
    top: -34px;
    left: 23%;
    margin-left: -5px;
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
}

.item-title.truncated:hover .item-title-tooltip {
	visibility: visible;
}

.item-data {
	width: 40px;
	text-align: right;
}

.item-bar {
	width: 270px;
}

.hide-bar {
	display: none;
}

.bar-chart-rect {
	margin-left: 8px;
	height: 16px;
	background-image: linear-gradient(270deg, #4790d1 0%, #b4d0ed 100%);
	transition: all .6s ease-in-out;
}
.widget-frame {
	position:relative;
	background-color: white;
	/*max-width: 1032px;*/
	height: 550px;
	box-shadow: 0 1px 2px #cfc9c9;
	border-radius: 3px;
	grid-column: span 2;
	transition: all .6s ease-in-out;
}

.widget-frame.narrow{
	max-width: 504px;
	/*max-width: 472.5px;*/
	grid-column: span 1;
}

.widget-frame.sm{
	height: 323px;
}

.widget-frame.xs{
	height: 128px;
}

.widget-image {
	margin-left: 24px;
	height: 24px;
}

.widget-title {
	display: inline-block;
	margin-left: 8px;
	margin-top: 24px;
	font-size: 16px;
	/*font-weight: 700;*/
	font-family: 'Inter Bold', sans-serif;
}

.widget-title.no-image {
	margin-left:0px;
}
.widget-body {
	position:relative;
	margin-top: 24px;
}

.widget-footer {
	position:absolute;
	width: 100%;
	bottom: 0;
	height: 41px;
	border-top: 1px solid #CED2D9;
}

.widget-dropdown {
	background-color: transparent;
	color: #00539D;
	font-size: 13px;
	border-color: transparent;
}

.widget-dropdown:focus {
	outline: none;
}

.tabs {
	overflow: hidden;
	margin-left: 24px;
	margin-top: 16px;
}

.tab {
	/*width: 128px;*/
	padding-right: 8px;
	/*padding-left: 8px;*/
	height: 35px;
	float: left;
	font-size: 16px;
	margin-right: 24px;
	color: #909090;
	cursor: pointer;	
}

.tab:hover {
	border-bottom: 5px solid #ced2d9;
}

.tab.active {
	font-weight: 500;
	color: #000000;
	border-bottom: 5px solid #2A7FC9;
}



.page-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
	grid-gap: 24px;
	
	max-width: 1032px;
	padding-bottom: 44px;
	transition: all .6s ease-in-out;
}
.dd-wrapper {
  -webkit-user-select: none;
          user-select: none;
  position: relative;
  width: 180px;
  height: 40px;
}

.dd-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: left;
          align-items: left;
  /*justify-content: space-between;*/
  line-height: 40px;
  cursor: pointer;
  position: relative;
}

.dd-header-arrow {
  /*font-size: 1px;*/
  color: #2A7FC9;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.dd-header-title {
  margin-left: 24px;
  margin-right: 5px;
  font-size: 14px;
  color: #2A7FC9;
}

.angle-down {
  color: #000;
  margin-right: 20px;
}

.dd-list {
  margin: 0;
  list-style: none;
  z-index: 10;
  position: absolute;
  width: 105%;
  border: 1px solid #dfdfdf;
  border-top: none;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: rgba(255,255,255,1);
  box-shadow: 0 1px 2px #CFC9C9;
  font-weight: 400;
  padding: 15px 0;
  max-height: 215px;
    bottom: -15px;
    left: 8px;
  /*overflow-y: scroll;*/
  -webkit-overflow-scrolling: touch;
}

.dd-list-item {
  color: #909090;
  list-style: none;
  width: 100%;
  font-size: 14px;
  padding: 8px 16px;
  line-height: 20px;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dd-list-item-check {
  display: inline-block;
  margin-left: 8px;
}

.dd-list-item.selected {
  color: #2A7FC9;
}

.dd-list-item:hover {
  color: black;
  background-color: #F4F6F8;
  width: 100%;
}


.donut-chart-wrapper {
	width: 100%;
}

.donut-chart {
	display:inline-block;
	margin-left: 32px;
	width: 140px;
	height: 140px;
}

.donut-legend {
	display: inline-block;
	overflow: hidden;
	margin-right: 24px;
	margin-left: 24px;
	width: 284px;
	height: 140px;	
	vertical-align:text-bottom;
}

.donut-legend-item {
	overflow: hidden;
	display: inline-block;
	width: 82px;
	margin-right: 12px;
	vertical-align:text-bottom;
}

.donut-legend-number {
	margin-top: 30%;
	margin-left: calc(50% - 12.5px);
	height: 25px;
	width: 25px;
	border-radius: 50%;
	line-height: 26px;
	text-align: center;
	color: white;
	font-size: 14px;
}

.donut-legend-data {
	margin-top: 10%;
	height: 28px;
	line-height: 28px;
	font-size: 20px;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0.39px;
}

.donut-legend-make {
	font-size: 12px;
	text-align: center;
	line-height: 20px;
	height: 20px;
}

.donut-legend-model {
	font-size: 12px;
	text-align: center;
	line-height: 20px;
	height: 20px;
}

.donut-legend-defaultKey {
	font-size: 20px;
	text-align: center;
	line-height: 20px;
	height: 20px;
}

.donut-others {
	margin-left: calc(50% - 41px);
	width: 72px;
	height: 22px;
	border-radius: 12px;
	background-color: #82a6cc;
	color: #fff;
	font-size: 12px;
	font-weight: 700;
	text-align: center;
	line-height: 20px;
 	padding: 2px 0 0 3px;
	letter-spacing: 0.39px;
	font-family: "Inter regular", sans-serif;
}

/* Style for "Others ↓" */

.donut-table {
	font-size: 14px;
	width: calc(100% - 88px);
	margin-left: 32px;
	padding-bottom: 32px;
	empty-cells: hide;
	border-collapse:collapse;
	margin-top: 6px;
}

.donut-table th { 
	font-weight: 400
}

.donut-table td { 
	border-bottom: 1px solid #EEE;
	border-top: 1px solid #EEE;
}

.data-wrapper {
	width: 100%;
}

.data-wrapper.blurred {
	-webkit-filter: blur(8px);
	        filter: blur(8px);
	pointer-events: none;
}

.no-data {
	position: absolute;
	top: 50%;
	width: 100%;
	text-align: center;
	color: black;
	font-size: 24px;
}
.z-100 {
  z-index: 1000; }

body {
  color: black;
  font-family: 'Inter regular', sans-serif;
  height: 100%;
  min-width: 1024px;
  overflow-x: hidden;
  min-height: 100vh;
  position: relative;
  margin: 0;
  padding-bottom: 100px; }

.main-container {
  height: 100%;
  display: -webkit-flex;
  display: flex; }

.pac-footer-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }

.pac-footer {
  position: absolute;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  width: 30vw;
  min-width: 30em;
  max-width: 50em;
  color: #888888;
  height: 100px;
  line-height: 35px; }
  .pac-footer a {
    color: #0067B1; }

