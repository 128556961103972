 @import "variables.scss";


.Entertainment .chartjs-render-monitor, .line-chart .chartjs-render-monitor {
    max-width: inherit;
    min-height:300px;
    max-height: 300px;
}
    
.selector-label {
    display: $flex;
    align-self: $center;
    color: $black;
    font-size: $font14;
    font-weight: 700;
    letter-spacing: 0.23px;
}

.selector-row {
    justify-content: $space-between;
    flex-wrap: nowrap;
}

.line-chart {
    padding: 0 32px 32px 32px;
}

.sub-title {
    width: 197px;
    height: 24px;
    color: #909090;
    font-family: "Inter regular", sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.46px;
    line-height: 20px;
}

.legend {
    display: flex;
    align-items: center;
    justify-content: center;
    .line {
        margin-left: 62px;
        margin-right: 8px;
        height: 1px;
        width: 24px;
        border: 1px solid #2a7fc9;
        &.dashed {
            border-style: dashed;
            margin-left: 0;
        }
    }
    .key-label {
        width: 43px;
        height: 16px;
        color: #909090;
        font-family: "Inter regular", sans-serif;
        font-size: 12px;
        font-weight: 400;
        margin-right: 13px;
    }
    
}