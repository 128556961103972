.widget-frame {
	position:relative;
	background-color: white;
	/*max-width: 1032px;*/
	height: 550px;
	box-shadow: 0 1px 2px #cfc9c9;
	border-radius: 3px;
	grid-column: span 2;
	transition: all .6s ease-in-out;
}

.widget-frame.narrow{
	max-width: 504px;
	/*max-width: 472.5px;*/
	grid-column: span 1;
}

.widget-frame.sm{
	height: 323px;
}

.widget-frame.xs{
	height: 128px;
}

.widget-image {
	margin-left: 24px;
	height: 24px;
}

.widget-title {
	display: inline-block;
	margin-left: 8px;
	margin-top: 24px;
	font-size: 16px;
	/*font-weight: 700;*/
	font-family: 'Inter Bold', sans-serif;
}

.widget-title.no-image {
	margin-left:0px;
}
.widget-body {
	position:relative;
	margin-top: 24px;
}

.widget-footer {
	position:absolute;
	width: 100%;
	bottom: 0;
	height: 41px;
	border-top: 1px solid #CED2D9;
}

.widget-dropdown {
	background-color: transparent;
	color: #00539D;
	font-size: 13px;
	border-color: transparent;
}

.widget-dropdown:focus {
	outline: none;
}

.tabs {
	overflow: hidden;
	margin-left: 24px;
	margin-top: 16px;
}

.tab {
	/*width: 128px;*/
	padding-right: 8px;
	/*padding-left: 8px;*/
	height: 35px;
	float: left;
	font-size: 16px;
	margin-right: 24px;
	color: #909090;
	cursor: pointer;	
}

.tab:hover {
	border-bottom: 5px solid #ced2d9;
}

.tab.active {
	font-weight: 500;
	color: #000000;
	border-bottom: 5px solid #2A7FC9;
}



.page-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
	grid-gap: 24px;
	
	max-width: 1032px;
	padding-bottom: 44px;
	transition: all .6s ease-in-out;
}