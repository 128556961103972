.coverage-widget {
	height: 171px;
	grid-column: span 2;
	width: 1032px;
}

.coverage-header {
	font-size: 24px;
	font-weight: 700;
	line-height: 48px;
}

.coverage-body {
	display: grid;
	grid-gap: 24px;
	grid-auto-flow: column;
}

.coverage-token {
	position: relative;
	height: 122px;
	width: 187px;
}

.coverage-token-image-wrapper {
	position: absolute;
	left: 12px;
	height:48px;
	width: 48px;
	border-radius: 8px;
}

.coverage-token-image {
	position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.coverage-token-background {
	bottom: 0;
	position: absolute;
	height: 110px;
	width: 187px;
	background-color: white;
	border-radius: 3px;
   box-shadow: 0 1px 2px #cfc9c9;
}

.coverage-token-data {
	position: absolute;
	left: 72px;
	top: 12px;
	height: 48px;
	width: calc(100% - 72px);
	font-size: 36px;
}

.coverage-token-title {
	position: absolute;
	left: 72px;
	top: 60px;
	font-size: 16px;
	width: calc(100% - 72px);
	height: 26px;
}