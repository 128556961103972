.donut-chart-wrapper {
	width: 100%;
}

.donut-chart {
	display:inline-block;
	margin-left: 32px;
	width: 140px;
	height: 140px;
}

.donut-legend {
	display: inline-block;
	overflow: hidden;
	margin-right: 24px;
	margin-left: 24px;
	width: 284px;
	height: 140px;	
	vertical-align:text-bottom;
}

.donut-legend-item {
	overflow: hidden;
	display: inline-block;
	width: 82px;
	margin-right: 12px;
	vertical-align:text-bottom;
}

.donut-legend-number {
	margin-top: 30%;
	margin-left: calc(50% - 12.5px);
	height: 25px;
	width: 25px;
	border-radius: 50%;
	line-height: 26px;
	text-align: center;
	color: white;
	font-size: 14px;
}

.donut-legend-data {
	margin-top: 10%;
	height: 28px;
	line-height: 28px;
	font-size: 20px;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0.39px;
}

.donut-legend-make {
	font-size: 12px;
	text-align: center;
	line-height: 20px;
	height: 20px;
}

.donut-legend-model {
	font-size: 12px;
	text-align: center;
	line-height: 20px;
	height: 20px;
}

.donut-legend-defaultKey {
	font-size: 20px;
	text-align: center;
	line-height: 20px;
	height: 20px;
}

.donut-others {
	margin-left: calc(50% - 41px);
	width: 72px;
	height: 22px;
	border-radius: 12px;
	background-color: #82a6cc;
	color: #fff;
	font-size: 12px;
	font-weight: 700;
	text-align: center;
	line-height: 20px;
 	padding: 2px 0 0 3px;
	letter-spacing: 0.39px;
	font-family: "Inter regular", sans-serif;
}

/* Style for "Others ↓" */

.donut-table {
	font-size: 14px;
	width: calc(100% - 88px);
	margin-left: 32px;
	padding-bottom: 32px;
	empty-cells: hide;
	border-collapse:collapse;
	margin-top: 6px;
}

.donut-table th { 
	font-weight: 400
}

.donut-table td { 
	border-bottom: 1px solid #EEE;
	border-top: 1px solid #EEE;
}

.data-wrapper {
	width: 100%;
}

.data-wrapper.blurred {
	filter: blur(8px);
	pointer-events: none;
}

.no-data {
	position: absolute;
	top: 50%;
	width: 100%;
	text-align: center;
	color: black;
	font-size: 24px;
}