@import "variables.scss";

.card-body {
    
    .yaxis-labels {

      font-size: 0.8rem;
      font-weight: 400;
      letter-spacing: 0.46px;
      list-style-type: none;
      padding-left: 5px;
      flex-grow: 1;
      width: 10%;
      display: $flex;
      flex-direction: $column;
      justify-content: $space-between;
      li {
        
        label {
          font-weight: bold;
          padding-right: 15px;
        }
        border-bottom: 1px solid #eeeeee;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        line-height: 30px;
      }
      
      li:last-of-type {
        border-bottom: none;
        label {
          padding-right: 9px;
        }
      }
    }
    
    .less-bar-count-labels li{
          height: 22%;
    }
    
    .chartjs-render-monitor,  {
       max-width:100%;
    }

  }
  
  