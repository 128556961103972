@charset "UTF-8";
/* ----------- Flex ----------------*/
/* ----------- Flex ----------------*/
/* ----------- Alignment ------------ */
/* ----------- Alignment ------------ */
/* ------------ Size ----------------*/
/* ------------ Size ----------------*/
/* ----------- Colors --------------*/
.side-bar.collapsed {
  padding-right: 0px;
  transition: padding-right 0.15s ease; }

.side-bar {
  position: static;
  padding-right: 0px;
  transition: padding-right 0.5s ease; }
  .side-bar > div > div > div > div {
    top: 21%; }

.not-clickable {
  pointer-events: none; }

.sidebar.collapsed {
  flex: 0 0 28px; }

.sidebar {
  background-color: #f3f5f7;
  width: 248px;
  flex: 0 0 248px;
  border-right: 1px solid #c3d4e3;
  font-family: 'Robot regular', sans-serif;
  padding: 24px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  margin-left: 64px;
  transition: all .25s ease-in-out; }
  .sidebar .app-name {
    font-family: "Raleway Extrabold", sans-serif;
    font-size: 20px;
    line-height: 19px;
    color: #112457;
    padding-bottom: 18px;
    margin: 0px 0px 16px;
    width: 195px;
    border-bottom: 1px solid #c3d4e3; }
  .sidebar .sidebar-expand {
    display: none; }
  .sidebar .sidebar-expand:first-child {
    display: initial;
    position: absolute;
    top: 0;
    left: -7px; }
  .sidebar .sidebar-collapse {
    position: absolute;
    right: 10px;
    top: 3px; }
  .sidebar ul {
    margin-top: 20px;
    position: fixed;
    height: 100%; }
  .sidebar #accordion {
    overflow-y: auto; }
  .sidebar #accordion::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #0155b8; }
  .sidebar #accordion::-webkit-scrollbar {
    width: 0px;
    background-color: #0155b8; }
  .sidebar #accordion::-webkit-scrollbar-thumb {
    background-color: #0155b8; }
  .sidebar .menu-icon {
    width: 20px;
    height: 20px; }
  .sidebar .currentSection {
    margin-top: 0px;
    padding: 26px 30px;
    font-size: larger;
    font-variant: small-caps;
    color: #b2d7ff; }
  .sidebar li.nav-item:first-of-type .sidebar-menu-item:first-of-type:not(.level) {
    padding-top: 0; }
  .sidebar .sidebar-menu-item.level {
    padding-left: 40px;
    padding-top: 10px; }
    .sidebar .sidebar-menu-item.level a, .sidebar .sidebar-menu-item.level span {
      font-size: 14px; }
  .sidebar .sidebar-menu-item:not(.no-page) {
    cursor: pointer; }
  .sidebar .sidebar-menu-item {
    padding: 20px 0px 0; }
    .sidebar .sidebar-menu-item .sidebar-icon {
      margin-right: 10px; }
    .sidebar .sidebar-menu-item .accordion-link:after {
      font-family: "Font Awesome 5 free", sans-serif;
      content: "\f0d7";
      padding-right: 10px;
      font-weight: 900;
      margin-left: -15px; }
    .sidebar .sidebar-menu-item .accordion-link[aria-expanded="true"]:after {
      content: "\f0d8"; }
    .sidebar .sidebar-menu-item a.menu-selected {
      font-weight: 700; }
  .sidebar a,
  .sidebar span {
    color: #384f75;
    font-size: 1rem;
    letter-spacing: 0.3px;
    align-items: center; }
  .sidebar a, .sidebar .menu-group {
    display: inline-block;
    width: 170px; }

/* ----------- Flex ----------------*/
/* ----------- Flex ----------------*/
/* ----------- Alignment ------------ */
/* ----------- Alignment ------------ */
/* ------------ Size ----------------*/
/* ------------ Size ----------------*/
/* ----------- Colors --------------*/
.card-body .yaxis-labels {
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 0.46px;
  list-style-type: none;
  padding-left: 5px;
  flex-grow: 1;
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .card-body .yaxis-labels li {
    border-bottom: 1px solid #eeeeee;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    line-height: 30px; }
    .card-body .yaxis-labels li label {
      font-weight: bold;
      padding-right: 15px; }
  .card-body .yaxis-labels li:last-of-type {
    border-bottom: none; }
    .card-body .yaxis-labels li:last-of-type label {
      padding-right: 9px; }

.card-body .less-bar-count-labels li {
  height: 22%; }

.card-body .chartjs-render-monitor {
  max-width: 100%; }

/* ----------- Flex ----------------*/
/* ----------- Flex ----------------*/
/* ----------- Alignment ------------ */
/* ----------- Alignment ------------ */
/* ------------ Size ----------------*/
/* ------------ Size ----------------*/
/* ----------- Colors --------------*/
.Entertainment .chartjs-render-monitor, .line-chart .chartjs-render-monitor {
  max-width: inherit;
  min-height: 300px;
  max-height: 300px; }

.selector-label {
  display: flex;
  align-self: center;
  color: #000000;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.23px; }

.selector-row {
  justify-content: space-between;
  flex-wrap: nowrap; }

.line-chart {
  padding: 0 32px 32px 32px; }

.sub-title {
  width: 197px;
  height: 24px;
  color: #909090;
  font-family: "Inter regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.46px;
  line-height: 20px; }

.legend {
  display: flex;
  align-items: center;
  justify-content: center; }
  .legend .line {
    margin-left: 62px;
    margin-right: 8px;
    height: 1px;
    width: 24px;
    border: 1px solid #2a7fc9; }
    .legend .line.dashed {
      border-style: dashed;
      margin-left: 0; }
  .legend .key-label {
    width: 43px;
    height: 16px;
    color: #909090;
    font-family: "Inter regular", sans-serif;
    font-size: 12px;
    font-weight: 400;
    margin-right: 13px; }

/* ----------- Flex ----------------*/
/* ----------- Flex ----------------*/
/* ----------- Alignment ------------ */
/* ----------- Alignment ------------ */
/* ------------ Size ----------------*/
/* ------------ Size ----------------*/
/* ----------- Colors --------------*/
.chart-title {
  width: 300px;
  height: 24px;
  color: #000000;
  font-family: "Inter regular", sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.26px;
  margin-top: 24px;
  margin-left: 25px;
  margin-right: 25px; }
  .chart-title img {
    margin-left: 0; }
  .chart-title span {
    margin-left: 8px; }

.num-views {
  width: 182px;
  height: 16px;
  color: #000000;
  font-family: "Inter regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.27px;
  padding-left: 24px;
  margin-bottom: 15px; }

.line-chart {
  padding-left: 24px;
  padding-right: 32px; }
  .line-chart.small-left-padding {
    padding-left: 20px; }
  .line-chart .chartjs-render-monitor {
    height: 282px;
    color: #7d8696;
    font-family: "Inter regular", sans-serif;
    font-size: 16px;
    font-weight: 400; }
  .line-chart .chartjs-render-monitor-pop {
    height: 582px;
    color: #7d8696;
    font-family: "Inter regular", sans-serif;
    font-size: 16px;
    font-weight: 400; }

.card-body {
  padding: 24px;
  padding-bottom: 0; }
  .card-body.min-top {
    padding-top: 33px;
    margin-bottom: 15px; }

.chart-wrapper {
  max-height: 285px; }

.card-wrapper {
  max-height: 466px; }

.dropdown {
  padding-left: 21px; }
  .dropdown .btn {
    padding-left: 0; }

.vertical-bar-legend-token {
  display: inline-block;
  margin-right: 18px;
  margin-bottom: 25px;
  height: 22px;
  border-radius: 12px;
  color: white;
  font-size: 12px;
  line-height: 22px;
  text-align: center; }

.vertical-bar-legend-token.low {
  margin-left: 35px;
  background-color: #79C2D0;
  width: 50px; }

.vertical-bar-legend-token.med {
  background-color: #60A5EE;
  width: 72px; }

.vertical-bar-legend-token.high {
  background-color: #00539D;
  width: 52px; }

.Low {
  border-radius: 25px;
  background-color: #79c2d0;
  width: 50px;
  height: 22px;
  border: #a2d4ea;
  margin-left: 35px;
  margin-bottom: 25px;
  margin-right: 25px; }

.Low_label {
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.39px;
  line-height: 22px;
  align-content: center;
  text-align: center;
  font-family: "Inter regular", sans-serif; }

.Medium {
  border-radius: 25px;
  background-color: #60a5ee;
  width: 72px;
  height: 18px;
  border: #afc5f0;
  margin-right: 25px; }

.High {
  border-radius: 25px;
  background-color: #00539d;
  width: 52px;
  height: 18px;
  border: #006cda; }

.completion_views {
  color: #7d8696; }

.completion_completion {
  color: #7d8696;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.23px; }

/* ----------- Flex ----------------*/
/* ----------- Flex ----------------*/
/* ----------- Alignment ------------ */
/* ----------- Alignment ------------ */
/* ------------ Size ----------------*/
/* ------------ Size ----------------*/
/* ----------- Colors --------------*/
.posters {
  width: 100%; }

.poster-label {
  display: flex;
  justify-content: center;
  margin-left: -32px;
  margin-top: 10px;
  font-weight: bold; }

.slick-next:before,
.slick-prev:before {
  color: #2a7fc9; }

.slick-prev {
  left: 0; }

.slick-next {
  right: 0; }

.slick-list {
  margin-left: 40px;
  margin-right: 15px;
  height: 360px; }

.cabin-class {
  margin-top: 6%; }
  .cabin-class h6 {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.46px;
    line-height: 20px; }
  .cabin-class h5 {
    font-weight: bold; }
  .cabin-class .airplane {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto; }
  .cabin-class .economy {
    position: absolute;
    left: 20%;
    top: 30%; }
    .cabin-class .economy h5 {
      color: #00539d; }
    .cabin-class .economy .economy-bar {
      background-color: #00539d; }
  .cabin-class .p-economy {
    position: absolute;
    left: 49%;
    top: 29%; }
    .cabin-class .p-economy h5 {
      color: #79c2d0; }
    .cabin-class .p-economy .p-economy-bar {
      background-color: #79c2d0; }
  .cabin-class .business {
    position: absolute;
    left: 67%;
    top: 29%; }
    .cabin-class .business h5 {
      color: #82a6cc; }
    .cabin-class .business .business-bar {
      background-color: #82a6cc; }
  .cabin-class .first {
    position: absolute;
    left: 86%;
    top: 29%; }
    .cabin-class .first h5 {
      color: #60a5ee; }
    .cabin-class .first .first-bar {
      background-color: #60a5ee; }
  .cabin-class .flight-bar {
    position: absolute;
    width: 3px;
    height: 50px;
    display: inline-block; }

.movie-title {
  color: #000000;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.49px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 95%;
  line-height: 30px; }

.movie-title-value {
  color: #4e5d78;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.46px;
  line-height: 20px; }

.item {
  position: relative;
  padding-top: 20px;
  display: inline-block; }

.notify-badge {
  position: absolute;
  left: 5px;
  height: 27px;
  width: 22px;
  right: -20px;
  top: 10px;
  z-index: 1;
  text-align: center;
  border-radius: 30px 30px 30px 30px;
  color: white;
  padding: 5px 10px;
  font-size: 20px; }

.parent {
  position: relative;
  margin-right: 10px; }

.poster {
  height: 220px;
  width: 180px;
  background-size: contain;
  background-repeat: no-repeat; }

.ribbon {
  position: absolute;
  top: -15px;
  left: -25px;
  height: 55px;
  width: 75px;
  z-index: 1; }

.rank {
  position: absolute;
  top: 0px;
  left: 7px;
  height: 25px;
  width: 24;
  z-index: 1;
  color: #ffffff;
  font-family: "Inter Semibold", sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.26px; }

.chartjs-size-monitor {
  height: 100%; }

@font-face {
  font-family: 'Inter regular';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Inter/Inter web/Inter-Regular.woff2") format("woff2"), url("../fonts/Inter/Inter web/Inter-Regular.woff") format("woff"); }

@font-face {
  font-family: 'Inter Semibold';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Inter/Inter web/Inter-SemiBold.woff2") format("woff2"), url("../fonts/Inter/Inter web/Inter-SemiBold.woff") format("woff"); }

@font-face {
  font-family: 'Inter Bold';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Inter/Inter web/Inter-Bold.woff2") format("woff2"), url("../fonts/Inter/Inter web/Inter-Bold.woff") format("woff"); }

@font-face {
  font-family: 'Raleway Extrabold';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Raleway/raleway-extrabold-webfont.woff2") format("woff2"), url("../fonts/Raleway/raleway-extrabold-webfont.woff") format("woff"); }

@font-face {
  font-family: 'Robot regular';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Robot/roboto-regular-webfont.woff2") format("woff2"), url("../fonts/Robot/roboto-regular-webfont.woff") format("woff"); }

@font-face {
  font-family: 'Robot medium';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Robot/roboto-medium-webfont.woff2") format("woff2"), url("../fonts/Robot/roboto-medium-webfont.woff") format("woff"); }

/* Inter UI Font */
@font-face {
  font-family: 'Inter-UI';
  font-weight: 400;
  src: url("../fonts/Inter-UI/Inter-UI-Regular.woff2") format("woff2"), url("../fonts/Inter-UI/Inter-UI-Regular.woff") format("woff"); }

@font-face {
  font-family: 'Inter-UI';
  font-weight: 600;
  src: url("../fonts/Inter-UI/Inter-UI-Medium.woff2") format("woff2"), url("../fonts/Inter-UI/Inter-UI-Medium.woff") format("woff"); }

@font-face {
  font-family: 'Inter-UI';
  font-weight: 700;
  src: url("../fonts/Inter-UI/Inter-UI-Bold.woff2") format("woff2"), url("../fonts/Inter-UI/Inter-UI-Bold.woff") format("woff"); }

.view-more {
  line-height: 40px;
  position: absolute;
  right: 24px;
  top: 2px;
  font-size: 14px;
  color: #2A7FC9;
  text-transform: capitalize; }
  .view-more span, .view-more a {
    color: #2A7FC9;
    text-decoration: none; }

.header .page-title {
  padding-left: 0; }

.components-wrapper .disable-date {
  display: none;
  width: 280px;
  position: absolute;
  z-index: 999;
  height: 50px;
  right: 440px;
  background-color: #ccc;
  opacity: .6;
  top: -88px; }

#paginator {
  display: flex;
  font-family: 'Inter regular', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 10px; }
  #paginator span {
    padding: 6px 0 0 5px; }
  #paginator span:first-child {
    padding: 6px 5px 0 0; }
  #paginator span.total-pages {
    padding-left: 5px; }
  #paginator .arrows-buttons {
    margin: 7px 0 0 20px;
    display: flex;
    position: relative;
    cursor: pointer; }
    #paginator .arrows-buttons i {
      transform: scale(1.22, 0.9); }
      #paginator .arrows-buttons i:first-child {
        margin-right: 25px; }
      #paginator .arrows-buttons i.disabled {
        opacity: .3; }
    #paginator .arrows-buttons div.disable-cover {
      position: absolute;
      z-index: 999;
      width: 22px;
      height: 18px;
      left: -3px; }
      #paginator .arrows-buttons div.disable-cover.forward {
        right: -3px;
        left: initial; }
  #paginator .workbench-select-container {
    margin: 0;
    color: #000;
    font-size: 16px; }
    #paginator .workbench-select-container .workbench-select__control {
      width: 60px; }
    #paginator .workbench-select-container .workbench-select__indicator-separator {
      display: none; }
    #paginator .workbench-select-container .workbench-select__dropdown-indicator {
      padding: 2px;
      width: 20px; }
      #paginator .workbench-select-container .workbench-select__dropdown-indicator::before {
        font-family: "Font Awesome 5 Free", sans-serif;
        font-weight: 900;
        content: "\f0dd";
        margin-top: -4px; }
      #paginator .workbench-select-container .workbench-select__dropdown-indicator svg {
        display: none; }
    #paginator .workbench-select-container .workbench-select__menu {
      width: 70px !important; }
      #paginator .workbench-select-container .workbench-select__menu .workbench-select__menu-notice--no-options {
        font-size: 12px;
        color: black; }
  #paginator .total-pages {
    margin-right: 15px; }

.rdg-selected {
  border: none !important; }

div.react-grid-Container .react-grid-Main {
  outline: none;
  box-shadow: 0 1px 2px #cfc9c9;
  border-radius: 3px; }
  div.react-grid-Container .react-grid-Main .react-grid-Grid {
    border: none; }
    div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header {
      font-size: 16px;
      font-family: 'Inter regular', sans-serif;
      background-color: #fff;
      overflow: hidden; }
      div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow {
        width: 100% !important; }
        div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell {
          background-color: #fff;
          height: 24px;
          color: #000000;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          text-transform: capitalize;
          border: none;
          cursor: pointer;
          padding-right: 10px;
          padding-top: 12px; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .active-sort {
            color: #2A7FC9; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .pull-right {
            display: none; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .fa.fa-sort {
            color: #e4e4e4;
            font-size: 17px;
            margin-left: 3px; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .fa.active {
            position: absolute;
            z-index: 0;
            color: #2a7fc9;
            margin-left: -10px;
            font-size: 17px; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .fa.fa-sort-up {
            top: 15px;
            visibility: hidden; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .fa.fa-sort-down {
            top: 15px;
            visibility: hidden; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .fa.fa-sort-down.first-render {
            visibility: visible; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .react-grid-HeaderCell-sortable--ascending .header-label {
            color: #2A7FC9; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .react-grid-HeaderCell-sortable--ascending .fa.fa-sort-down {
            visibility: hidden; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .react-grid-HeaderCell-sortable--ascending .fa.fa-sort-up {
            visibility: visible; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .react-grid-HeaderCell-sortable--descending .header-label {
            color: #2A7FC9; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .react-grid-HeaderCell-sortable--descending .fa.fa-sort-down {
            visibility: visible; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .react-grid-HeaderCell-sortable--descending .fa.fa-sort-up {
            visibility: hidden; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid div.react-grid-Header div.react-grid-HeaderRow div.react-grid-HeaderCell .react-grid-HeaderCell__draggable {
            width: 3px !important;
            margin: 0 1px 0 1px;
            background-color: #ededed; }
    div.react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Viewport .react-grid-Canvas {
      overflow-x: auto !important;
      overflow-y: hidden !important;
      z-index: 0;
      background-color: #fff; }
      div.react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Viewport .react-grid-Canvas > div:last-child {
        border-bottom: 1px #ededed solid; }
      div.react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Viewport .react-grid-Canvas > div .react-grid-Row {
        height: 48px; }
        div.react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Viewport .react-grid-Canvas > div .react-grid-Row:last-child {
          border-bottom: 1px #000 solid; }
        div.react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Viewport .react-grid-Canvas > div .react-grid-Row.react-grid-Row--even .react-grid-Cell {
          background-color: #ededed; }
        div.react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Viewport .react-grid-Canvas > div .react-grid-Row.react-grid-Row--odd .react-grid-Cell {
          background-color: #fff; }
        div.react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Viewport .react-grid-Canvas > div .react-grid-Row div.react-grid-Cell {
          font-size: 16px;
          border: none; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Viewport .react-grid-Canvas > div .react-grid-Row div.react-grid-Cell.rdg-last--frozen {
            box-shadow: none; }
          div.react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Viewport .react-grid-Canvas > div .react-grid-Row div.react-grid-Cell .react-grid-Cell__value div {
            width: 95%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }
            div.react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Viewport .react-grid-Canvas > div .react-grid-Row div.react-grid-Cell .react-grid-Cell__value div .grid-link {
              color: #2A7FC9;
              letter-spacing: .45px;
              cursor: pointer; }

/******** Your Insights ******/
.card-container {
  width: 1080px; }
  .card-container .slick-arrow {
    position: absolute;
    top: 145px;
    height: 40px;
    width: 40px;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    border: 3px solid #4091D7;
    /* color: #4091D7; */
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    z-index: 1;
    -webkit-transition: all .6s ease-in-out;
    transition: all .6s ease-in-out;
    opacity: 1;
    box-sizing: content-box; }
  .card-container .slick-slider {
    left: -40px; }
  .card-container .slick-prev {
    left: 42px; }
  .card-container .slick-prev:before {
    content: '‹';
    font-family: 'Inter Semibold', sans-serif; }
  .card-container .slick-next:before {
    content: '›';
    font-family: 'Inter Semibold', sans-serif; }

.optimize-content {
  color: white;
  position: absolute;
  top: 125px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  font-size: 25px; }

div.card-layout {
  width: 225px !important;
  position: relative;
  height: 296px;
  box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background-color: #ffffff; }

div.card-layout:not(:last-child) {
  margin-right: 26px; }

.optimization-poster {
  background-position: left top, right top;
  background-size: 120px 190px;
  background-repeat: no-repeat;
  width: 225px;
  height: 190px;
  background-color: #2a7fc9;
  /* left: 10px; */
  margin-left: -1px; }

.card-overlay {
  position: absolute;
  height: 190px;
  width: 225px;
  opacity: 0.5;
  background: black;
  top: 55px;
  left: 0px; }

.optimize-count {
  height: 63px;
  color: #ffffff;
  font-family: 'Inter Semibold', sans-serif;
  font-size: 88px;
  font-weight: 600; }

.optimize-subtext {
  width: 239px;
  height: 24px;
  color: #ffffff;
  font-family: 'Inter Regular', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center; }

.optimize-link {
  display: flex;
  justify-content: flex-end;
  height: 24px;
  color: #2264a0;
  font-family: "Inter Regular", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 10px; }

.optimization img {
  object-fit: none;
  height: 177px;
  filter: blur(1.5px); }

.manage-media .modal-content {
  width: 700px;
  top: -150px;
  height: 370px; }

/******** Your Insights ******/
.innoviation-title {
  margin-left: 64px;
  margin-top: 24px;
  width: 472px;
  height: 32px;
  color: #000000;
  font-family: 'Inter regular', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px; }

.innovation-aggregate-score {
  width: 248px;
  height: 20px;
  color: #000000;
  font-family: 'Inter regular', sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-top: 3%; }

.innovation-aggreate-currentscore {
  width: 79px;
  height: 78px;
  color: #000000;
  font-family: 'Inter regular', sans-serif;
  font-size: 64px;
  font-weight: 700; }

.innovation-aggreate-currentotal {
  width: 76px;
  height: 39px;
  color: #a2a2a2;
  font-family: 'Inter regular', sans-serif;
  font-size: 32px;
  font-weight: 400;
  margin-right: 15px; }

.innovation-reddown {
  width: 18px;
  height: 26px;
  transform: rotate(90deg); }

.innovation-downgradevalue {
  width: 47px;
  height: 42px;
  color: #d24646;
  font-family: 'Inter regular', sans-serif;
  font-size: 36px;
  font-weight: 700; }

.fa-arrow-down {
  font-size: 35px;
  color: red; }

.fa-arrow-up:before, .fa-arrow-down:before {
  padding-right: 5px; }

.fa-arrow-up {
  font-size: 35px;
  color: green; }

.innovation-trend-performance {
  color: #000000;
  font-family: "Inter Semibold", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  margin-top: 27px; }

.flipchart {
  transform: scaleX(-1); }

.acronym {
  font-size: 11px;
  font-weight: bold;
  color: gray;
  width: 232px;
  padding-bottom: 10px; }

.flip-horizontal, .x-grid-cell-inner, .x-column-header-text, .x-panel-header-text {
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph;
  /*IE*/
  filter: fliph;
  /*IE*/ }

.x-column-header-text, .x-panel-header-text {
  display: inline-block; }

.innovation-card-trend {
  border-radius: 2px;
  background-color: #ffffff; }

.innovation-feedback {
  width: 129px;
  height: 20px;
  color: #000000;
  font-family: 'Inter regular', sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-left: 252px;
  margin-right: 27px; }

.innovation-trend-image {
  width: 167px;
  height: 270px;
  padding-bottom: 7px;
  padding-top: 24px; }

.manage-detail .modal-content {
  max-width: 871px;
  min-width: 871px;
  min-height: 400px;
  top: -230px;
  left: -130px;
  border: none;
  box-shadow: 0 1px 8px #979797;
  border-radius: 10px;
  background-color: #ffffff; }
  .manage-detail .modal-content .modal-header {
    padding: 0;
    height: 0; }
    .manage-detail .modal-content .modal-header h5.modal-title {
      color: white; }
  .manage-detail .modal-content .close {
    font-size: 60px;
    font-weight: lighter;
    opacity: 0.7;
    margin-top: -30px;
    padding: 0;
    cursor: pointer;
    outline: none; }
  .manage-detail .modal-content .close:before, .manage-detail .modal-content .close:after {
    display: none; }
  .manage-detail .modal-content .carousel-placeholder-image {
    top: 27%;
    left: 12%; }

.custom-control.custom-switch {
  width: 0;
  height: 0;
  top: 100px;
  left: -20px;
  float: right;
  z-index: 1;
  padding-left: 0;
  min-height: 0; }

.valign-mid {
  vertical-align: middle; }

.page-header {
  color: #072457;
  font-size: 36px;
  font-family: 'Inter Bold', sans-serif;
  font-style: normal;
  letter-spacing: 0.45px;
  font-weight: bold;
  position: relative;
  top: -90px;
  left: 0px; }

.modal-header,
.modal-body,
.modal-footer {
  border: 0; }

.modal-body .linechart-pop-align {
  padding-top: 135px; }
  .modal-body .linechart-pop-align .min-top.card-body {
    padding-left: 0; }

.modal-backdrop {
  background-color: white; }

.modal-backdrop.show {
  opacity: 0.8; }

.modal-header h5 {
  font-family: "Inter Bold", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px; }

.modal-body {
  font-family: "Inter Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 24px; }

.modal-content .primary.btn-secondary {
  background-color: #2a7fc9;
  border: 1px solid #2a7fc9; }

.main-content {
  background-color: #f9f9f9;
  flex-grow: 1;
  overflow-x: hidden;
  padding: 0 48px 48px 48px; }
  .main-content .col {
    padding-left: 1;
    padding-right: 0; }
  .main-content .card {
    box-shadow: 0 1px 2px #cfc9c9;
    min-width: 1032px;
    max-width: 1032px;
    border: none;
    height: 550px; }
  .main-content .card.bar-chart {
    min-width: 504px;
    max-width: 504px; }
  .main-content .small-bar-chart {
    height: 316px;
    min-width: 504px;
    max-width: 504px; }
  .main-content .bar-chart-gap {
    margin-right: 24px; }
  .main-content .align-component {
    width: 1032px;
    margin-bottom: 26px; }
  .main-content .components-wrapper {
    height: 100%;
    margin-right: 2px; }
  .main-content .header {
    background-color: #f9f9f9;
    position: relative;
    display: flex;
    max-width: 1032px;
    padding-left: 0px;
    padding-top: 13px;
    align-items: center;
    height: 107px; }
  .main-content .selector-row {
    justify-content: flex-end;
    align-items: flex-end;
    flex-shrink: 0;
    margin-top: -50px; }
  .main-content .selector-label {
    display: flex;
    align-self: center;
    color: #000000;
    font-size: 14px;
    letter-spacing: 0.23px;
    padding-right: 48px; }
  .main-content .page-title {
    color: #072457;
    font-size: 36px;
    font-family: 'Inter Bold', sans-serif;
    font-style: normal;
    letter-spacing: 0.45px;
    width: 30vw;
    float: left;
    padding-left: 0;
    margin-top: -50px; }
  .main-content .quick-sight {
    letter-spacing: 0px;
    width: 30vw;
    float: left;
    padding-left: 0px;
    margin-top: 0px; }
  .main-content .component-title {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.45px; }
  .main-content .component-subtitle {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.45px; }
  .main-content .month-selector .select__control {
    width: 142px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #2a7fc9;
    background-color: #2a7fc9; }
    .main-content .month-selector .select__control .select__value-container {
      justify-content: center; }
      .main-content .month-selector .select__control .select__value-container .select__placeholder {
        color: white; }
  .main-content .month-selector .select__indicator-separator {
    display: none; }
  .main-content .month-selector .select__single-value {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.26px; }
  .main-content .month-selector .select__dropdown-indicator svg {
    fill: #ffffff; }

.card.mask {
  background-color: lightgray; }

.card.mask:before {
  content: 'Draft view';
  color: orange;
  font-size: 20px;
  text-align: center;
  margin-top: 10px; }

.container-fluid {
  padding: 0; }

.container-fluid.main-container .card-body h4 {
  font-size: 1rem;
  font-weight: bold; }

.container-fluid.main-container .row {
  flex-wrap: nowrap;
  margin-left: 0;
  margin-right: 0; }

.container-fluid.main-container .mt-3 .row {
  min-width: 1032px;
  max-width: 1032px; }

.container-fluid.main-container .row.align-component .col:first-of-type {
  padding-right: 8px; }

.container-fluid.main-container .row.align-component .col:nth-of-type(2) {
  padding-left: 8px; }

div.link-arrow {
  margin: 0 0 0 5px;
  color: #2A7FC9;
  transform: rotate(180deg); }

.card-footer {
  background-color: transparent;
  padding: 1px 0 1px 0;
  height: 40px;
  position: absolute;
  bottom: 0;
  width: 100%; }
  .card-footer .nav-link {
    font-family: 'Inter regular', sans-serif;
    font-size: 14px;
    margin-right: 15px; }
    .card-footer .nav-link a {
      display: flex;
      color: #2a7fc9; }
  .card-footer .abs-right {
    position: absolute;
    right: 18px;
    top: 11px;
    padding: 0;
    display: flex; }
  .card-footer .dropdown .btn {
    color: #2a7fc9;
    font-family: 'Inter regular', sans-serif;
    font-size: 14px; }
    .card-footer .dropdown .btn .select__indicator-separator {
      display: none; }
  .card-footer .dropdown .dropdown-item {
    color: #2a7fc9;
    font-family: 'Inter regular', sans-serif;
    font-size: 14px; }

.fixed {
  position: fixed; }

.footer {
  background-color: transparent; }

/*------- Dark theme ---------*/
.dark .logo-wrapper {
  background-color: black; }

.dark #sidebar {
  background-color: #181818; }
  .dark #sidebar .menu-item1 {
    background-color: #181818; }
  .dark #sidebar .menu-item2 {
    background-color: #101010; }

.dark .main-content {
  background-color: black; }
  .dark .main-content .select__control {
    background-color: black; }
  .dark .main-content .select__menu {
    background-color: black; }
    .dark .main-content .select__menu .select__option--is-focused {
      background-color: #181818; }
  .dark .main-content .card {
    background-color: #181818;
    color: white; }
  .dark .main-content .basic-multi-select {
    background-color: #181818;
    color: white; }

.dark .navbar {
  background-color: black;
  color: white; }

@keyframes blink {
  0% {
    opacity: .2; }
  20% {
    opacity: 1; }
  100% {
    opacity: .2; } }

.loading span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both; }

.loading span:nth-child(2) {
  animation-delay: .2s; }

.loading span:nth-child(3) {
  animation-delay: .4s; }

.current-user-photo {
  margin-left: -10px;
  margin-top: -10px;
  clip-path: circle(17px at center); }
