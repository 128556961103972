.innoviation-title{
    margin-left:64px;
    margin-top:24px;
    width: 472px;
height: 32px;
color: #000000;
font-family: 'Inter regular', sans-serif;
font-size: 24px;
font-weight: 700;
line-height: 32px;
}

.innovation-aggregate-score{
    width: 248px;
height: 20px;
color: #000000;
font-family: 'Inter regular', sans-serif;
font-size: 16px;
font-weight: 400;
margin-top:3%;
}

.innovation-aggreate-currentscore{
    width: 79px;
height: 78px;
color: #000000;
font-family: 'Inter regular', sans-serif;
font-size: 64px;
font-weight: 700;
}
.innovation-aggreate-currentotal{

width: 76px;
height: 39px;
color: #a2a2a2;
font-family: 'Inter regular', sans-serif;
font-size: 32px;
font-weight: 400;
margin-right: 15px;
}
.innovation-reddown{
    width: 18px;
height: 26px;
transform: rotate(90deg);
 }

.innovation-downgradevalue{
    
    width: 47px;
height: 42px;
color: #d24646;
font-family: 'Inter regular', sans-serif;
font-size: 36px;
font-weight: 700;
}

.fa-arrow-down {
    font-size: 35px;
    color: red;
}
.fa-arrow-up:before, .fa-arrow-down:before {
    padding-right:5px;
}
.fa-arrow-up {
    font-size: 35px;
    color: green;
}
.innovation-trend-performance{
 
color: #000000;
font-family: "Inter Semibold", sans-serif;
font-size: 16px;
font-weight: 500;
line-height: 16px;
 margin-top: 27px;
}

.flipchart{
 transform: scaleX(-1);
}

.acronym {
        font-size: 11px;
    font-weight: bold;
    color: gray;
    width: 232px;
    padding-bottom: 10px;
}


.flip-horizontal, .x-grid-cell-inner, .x-column-header-text, .x-panel-header-text {
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
    -ms-filter: fliph; /*IE*/
    filter: fliph; /*IE*/
}

.x-column-header-text, .x-panel-header-text {
    display: inline-block;
}

.innovation-card-trend{
// max-width: 871px;
// min-width: 871px;
// height: 527px;
// box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.2);
border-radius: 2px;
background-color: #ffffff;
 
}

.innovation-feedback{
    width: 129px;
height: 20px;
color: #000000;
font-family: 'Inter regular', sans-serif;
font-size: 16px;
font-weight: 400;

margin-left:252px;
margin-right:27px;
}

.innovation-trend-image{
  width: 167px;
height: 270px;
padding-bottom:7px;
padding-top:24px;
}

.manage-detail .modal-content{
     max-width: 871px;
    min-width: 871px;
    min-height: 400px;
    top: -230px;
    left: -130px;
    border: none;
    box-shadow: 0 1px 8px #979797;
    border-radius: 10px;
    background-color: #ffffff;
    .modal-header {
        padding: 0;
        height: 0;
        h5.modal-title {
            color: white;
        }
    }
    
    .close {
        font-size: 60px;
        font-weight: lighter;
        opacity: 0.7;
        margin-top: -30px;
        padding: 0;
        cursor: pointer;
        outline: none;
    }
    .close:before, .close:after {
        display: none;
    } 
    
    .carousel-placeholder-image {
            top: 27%;
    left: 12%;
    }
}