.view-more {
    line-height: 40px;
    position: absolute;
    right: 24px;
    top: 2px;
    font-size: 14px;
    color: #2A7FC9;
    text-transform: capitalize;
    
    span, a {
       color: #2A7FC9;
       text-decoration: none;
    }
}

.header {
   .page-title {
      padding-left: 0;
   }  
}

.components-wrapper {
   .disable-date {
       display: none;
       width: 280px;
       position: absolute;
       z-index: 999;
       height: 50px;
       right: 440px;
       background-color: #ccc;
       opacity: .6;
       top: -88px;
   }
}

#paginator {
   display: flex;
   font-family: 'Inter regular', sans-serif;
   font-size: 16px;
   font-weight: 400;
   line-height: 24px;
   margin-bottom: 10px;
   
   span {
      padding: 6px 0 0 5px;
   }
   
   span:first-child {
      padding: 6px 5px 0 0;
   }
   
   span.total-pages {
      padding-left: 5px;
   }
   
   .arrows-buttons {
       margin: 7px 0 0 20px;
       display: flex;
       position: relative;
       cursor: pointer;
      
      i {
         transform: scale(1.22, .9);
      
         &:first-child {
            margin-right: 25px;
         }
         
         &.disabled {
           opacity: .3;
         }
         
      }
      
      div.disable-cover {
         position: absolute;
         z-index: 999;
         width: 22px;
         height: 18px;
         left: -3px;
         
         &.forward {
            right: -3px;
            left: initial;
         }
      }
   }
   
   .workbench-select-container {
      margin: 0;
      color: #000;
      font-size: 16px;
      
      .workbench-select__control {
         width: 60px;
      }
      
      .workbench-select__indicator-separator {
         display: none;
      }
      
      .workbench-select__dropdown-indicator {
         padding: 2px;
         width: 20px;
         &::before {
           font-family: "Font Awesome 5 Free", sans-serif;
           font-weight: 900;
           content: "\f0dd";
           margin-top: -4px;
         }   
         svg {
            display: none;
         }
      }
      
      .workbench-select__menu {
         width: 70px !important;
         .workbench-select__menu-notice--no-options {
            font-size: 12px;
            color: black;
         }
         
      }
   }
   
   .total-pages {
      margin-right: 15px;
   }
}
   
.rdg-selected {
  border: none !important;
}
   
div.react-grid-Container {
   .react-grid-Main {
      outline: none;    
      box-shadow: 0 1px 2px #cfc9c9;
      border-radius: 3px;

      
      .react-grid-Grid {
         border: none;
         
         div.react-grid-Header {
            font-size: 16px;
            font-family: 'Inter regular', sans-serif;
            background-color: #fff;
            overflow: hidden;
            div.react-grid-HeaderRow {
               width: 100% !important;
               
               div.react-grid-HeaderCell {   
                  background-color: #fff;
                  height: 24px;
                  color: #000000;
                  font-size: 16px;
                  font-weight: 700;
                  line-height: 24px;
                  text-transform: capitalize;
                  border: none;
                  cursor: pointer;
                  padding-right:10px;
                  padding-top: 12px;
                  
                  .active-sort {
                     color: #2A7FC9;
                  }
                  .pull-right {
                     display: none;
                  }
                  .fa {
                     &.fa-sort {
                        color: #e4e4e4;
                        font-size: 17px;
                        margin-left: 3px;
                     }
                     &.active {
                        position: absolute;
                        z-index: 0;
                        color: #2a7fc9;
                        margin-left: -10px;
                        font-size: 17px;
                     }
                     &.fa-sort-up {
                        top: 15px;
                        visibility: hidden;
                     }
                     &.fa-sort-down {
                        top: 15px;
                        visibility: hidden;
                     }
                     &.fa-sort-down.first-render {
                        visibility: visible;
                     }
                  }
                  .react-grid-HeaderCell-sortable--ascending  {
                     .header-label {
                        color: #2A7FC9;
                     }
                     .fa.fa-sort-down {
                        visibility: hidden;
                     }
                     .fa.fa-sort-up {
                        visibility: visible;
                     }
                  }
                  .react-grid-HeaderCell-sortable--descending {
                     .header-label {
                        color: #2A7FC9;
                     }
                     .fa.fa-sort-down {
                        visibility: visible;
                     }
                     .fa.fa-sort-up {
                        visibility: hidden;
                     }
                  }
                  .react-grid-HeaderCell__draggable {
                     width: 3px !important;
                     margin: 0 1px 0 1px;
                     background-color: rgb(237, 237, 237);
                  }
               }
            }
            
            
         }
            
         .react-grid-Viewport {
             .react-grid-Canvas {
               overflow-x: auto !important;
               overflow-y: hidden !important;
               z-index: 0;
               background-color: #fff;
               > div:last-child {
                  border-bottom: 1px #ededed solid;
               }
               > div {
                 .react-grid-Row {
                     height: 48px;
                     &:last-child {
                        border-bottom: 1px #000 solid;
                     }
                     &.react-grid-Row--even .react-grid-Cell { 
                        background-color: #ededed;
                     }
                     &.react-grid-Row--odd .react-grid-Cell { 
                        background-color: #fff;
                     }
                     div.react-grid-Cell {
                        font-size: 16px;
                        border: none;
                  
                        &.rdg-last--frozen {
                           box-shadow: none;
                        }
                        
                        .react-grid-Cell__value div {
                           width: 95%;
                           white-space: nowrap;
                           overflow: hidden;
                           text-overflow: ellipsis;
                           
                           .grid-link {
                              color: #2A7FC9;
                              letter-spacing: .45px;
                              cursor: pointer;
                           }
                        }
                     }
                  }
               }
               
            }
         }
      }
   }
}

