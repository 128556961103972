 @import "variables.scss";

 .chart-title {
   width: 300px;
   height: 24px;
   color: #000000;
   font-family: "Inter regular", sans-serif;
   font-size: 16px;
   font-weight: 700;
   letter-spacing: 0.26px;
   margin-top: 24px;
   margin-left: 25px;
   margin-right: 25px;
   img {
    margin-left: 0;
   }
   span {
    margin-left: 8px;
   }
 }
 
 .num-views {
   width: 182px;
   height: 16px;
   color: #000000;
   font-family: "Inter regular", sans-serif;
   font-size: 14px;
   font-weight: 400;
   letter-spacing: 0.27px;
   padding-left: 24px;
   margin-bottom: 15px;
 }

 .line-chart {
   padding-left: 24px;
   padding-right: 32px;
   &.small-left-padding {
    padding-left: 20px;
   }

   .chartjs-render-monitor {
     height: 282px;
     color: #7d8696;
     font-family: "Inter regular", sans-serif;
     font-size: 16px;
     font-weight: 400;
   }
   .chartjs-render-monitor-pop {
     height: 582px;
     color: #7d8696;
     font-family: "Inter regular", sans-serif;
     font-size: 16px;
     font-weight: 400;
   }
 }
   
 
 .card-body {
    padding: 24px;
   padding-bottom: 0;
   &.min-top {
      padding-top: 33px;
      margin-bottom: 15px;
    }
 }

 .chart-wrapper {
   max-height: 285px;
 }

 .card-wrapper {
   max-height: 466px;
 }

 .dropdown {
   padding-left: 21px;
   .btn {
     padding-left: 0;
   }
 }

.vertical-bar-legend-token {
	display: inline-block;
	margin-right: 18px;
	margin-bottom: 25px;
	height: 22px;
	border-radius: 12px;
	color: white;
	font-size: 12px;
	line-height: 22px;
	text-align: center;
}

.vertical-bar-legend-token.low{
 margin-left: 35px;
	background-color: #79C2D0;
	width: 50px;
}

.vertical-bar-legend-token.med{
	background-color: #60A5EE;
	width: 72px;
}

.vertical-bar-legend-token.high{
	background-color: #00539D;
	width: 52px;
}


 .Low {
   border-radius: 25px;
   background-color: #79c2d0;
   width: 50px;
   height: 22px;
   border: #a2d4ea;
   margin-left: 35px;
   margin-bottom: 25px;
   margin-right: 25px;

 }

 .Low_label {
   color: #ffffff;
   font-size: 12px;
   font-weight: 400;
   letter-spacing: 0.39px;
   line-height: 22px;
   align-content: center;
   text-align: center;
   font-family: "Inter regular", sans-serif;
 }

 .Medium {
   border-radius: 25px;
   background-color: #60a5ee;
   width: 72px;
   height: 18px;
   border: #afc5f0;
   margin-right: 25px;

 }

 .High {
   border-radius: 25px;
   background-color: #00539d;
   width: 52px;
   height: 18px;
   border: #006cda; //  margin-right: 20px;
 }

 .completion_views {

   color: #7d8696; // margin-left: 50px;
 }

 .completion_completion {
   color: #7d8696;
   font-size: $font14;
   font-weight: 700;
   letter-spacing: 0.23px;
 }
 
 