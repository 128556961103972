.horizontal-bar-chart-wrapper {
	width: 100%;
}

.horizontal-bar {
	font-size: 14px;
	width: calc(100% - 88px);
	margin-left: 32px;
	padding-bottom: 32px;
	empty-cells: hide;
	border-collapse:collapse;
}

.horizontal-bar th { 
	font-weight: 400
}

.horizontal-bar td { 
	border-bottom: 1px solid #EEE;
}

.horizontal-bar-chart {
	display: inline-block;
	width: calc(50% - 32px);
	background-color: green;
}

.item-number {
	font-weight: 600;
	line-height: 32px;
    min-width: 20px;
}

.item-title {
	max-width: 140px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 32px;
}


.item-title .item-title-tooltip {
	visibility: hidden;
	background-color: #fff;
	color: #909090;
	text-align: center;
	border-radius: 6px;
	padding: 5px 16px;
	position: absolute;
	z-index: 1;
	left: 0px;
	margin-left: 32px;
	margin-top: 32px;
	box-shadow: 0 1px 8px #979797;
}

.item-title .item-title-tooltip::before {
	content: url(../../images/tooltipArrow.png);
    position: absolute;
    top: -34px;
    left: 23%;
    margin-left: -5px;
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
}

.item-title.truncated:hover .item-title-tooltip {
	visibility: visible;
}

.item-data {
	width: 40px;
	text-align: right;
}

.item-bar {
	width: 270px;
}

.hide-bar {
	display: none;
}

.bar-chart-rect {
	margin-left: 8px;
	height: 16px;
	background-image: linear-gradient(270deg, #4790d1 0%, #b4d0ed 100%);
	transition: all .6s ease-in-out;
}