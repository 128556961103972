 @import "variables.scss";

$sidebar-padding-right: 0px;
$sidebar-collapsed-padding-right: 0px;


.side-bar.collapsed {
  //flex: 0 0 121px;
  padding-right: $sidebar-collapsed-padding-right;
  transition: padding-right 0.15s ease;
}

.side-bar {
  position: static;
  // width: 329px;
  padding-right: $sidebar-padding-right;
  transition: padding-right 0.5s ease;
    
  & > div > div > div > div {
    top: 21%;
  }
}

.not-clickable {
  pointer-events: none
}

.sidebar.collapsed {
  flex: 0 0 28px;
}
.sidebar {
    background-color: rgb(243, 245, 247);
    width: 248px;
    flex: 0 0 248px;
    border-right: 1px solid rgb(195, 212, 227); 
    font-family: 'Robot regular', sans-serif;
    padding: 24px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    margin-left: 64px;
    transition: all .25s ease-in-out;
   
    .app-name { 
      font-family: "Raleway Extrabold", sans-serif;
      font-size: 20px;
      line-height: 19px;
      color: rgb(17, 36, 87);
      padding-bottom: 18px;
      margin: 0px 0px 16px;
      width: 195px;
      border-bottom: 1px solid rgb(195, 212, 227);
      
    }
    
    .sidebar-expand {
      display: none;
    }
    .sidebar-expand:first-child{
      display: initial;
      position: absolute;
      top: 0;
      left: -7px;
    }
    .sidebar-collapse {
      position: absolute;
      right: 10px;
      top: 3px;
    }
    
    
    ul {
      margin-top: 20px;
      position: fixed;
      height: 100%;
    }
    #accordion {
      overflow-y: auto;
    }
    
    #accordion::-webkit-scrollbar-track {
    	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    	background-color: #0155b8;
    }
      
    #accordion::-webkit-scrollbar {
    	width: 0px;
    	background-color: #0155b8;
    }
      
    #accordion::-webkit-scrollbar-thumb {
    	background-color: #0155b8;
    }
      
    .menu-icon {
      width: 20px;
      height: 20px;
    }

    .currentSection {
      margin-top: 0px;
      padding: 26px 30px;
      font-size: larger;
      font-variant: small-caps;
    //   font-weight: $font-regular;
      color: #b2d7ff;
    }

    li.nav-item:first-of-type .sidebar-menu-item:first-of-type:not(.level) {
      padding-top: 0;
    }
    .sidebar-menu-item.level {
      padding-left: 40px;
      padding-top: 10px;
      a, span {
            font-size: $font14;
      }
    }
    .sidebar-menu-item:not(.no-page) {
      cursor: pointer;
    }
    .sidebar-menu-item {
      padding: 20px 0px 0;
      
      .sidebar-icon { 
        margin-right: 10px;
      }

      .accordion-link:after {
        font-family: "Font Awesome 5 free", sans-serif;
        content: "\f0d7";
        padding-right: 10px;
        font-weight: 900;
        margin-left:-15px;

      }
      .accordion-link[aria-expanded="true"]:after {
        content: "\f0d8";

      }

      a.menu-selected {
        //color: white;
        font-weight: 700;
        
      }
    }
    a,
    span {
      color: rgb(56, 79, 117);
      font-size: 1rem;
      letter-spacing: 0.3px;
      align-items: center;
    }
    a, .menu-group {
        display: inline-block;
        width: 170px;
    }
  }
  
