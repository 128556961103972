@import "variables.scss";

.airlinesummary {
	height: 122px;
	box-shadow: 0 1px 2px #cfc9c9;
	border-radius: 3px;
	background-color: $white;
	margin-left: 0;
	margin-right: 0;
	.col {
		max-width: 20%;
	}
}

.clickable {
	cursor: pointer;
}

.coverage-icon {
	margin-right: 7px;
}

.airlinesummarycard {
	display: flex;
	justify-content: $center;
	align-items: $center;
	flex-direction: $column;
	margin-right: 24px;
	font-family: "Inter regular", sans-serif;
	min-width: 130px;
	margin-top: 6px;
	width: 176px;
	.cardtitle {
		color: #7d8696;
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0.26px;
	}
	.percent-title {
		width: 197px;
		height: 24px;
		color: #ea4335;
		font-family: "Inter regular", sans-serif;
		font-size: 16px;
		font-weight: 700;
		letter-spacing: 0.53px;
		line-height: 20px;
	}
	.carddata {
		width: 176px;
		height: 48px;
		color: #000000;
		font-family: "Inter regular", sans-serif;
		font-size: 36px;
		font-weight: 700;
		letter-spacing: 0.46px;
	}
	.carddata-smallfont {
		width: 120px;
		height: 48px;
		color: #000000;
		font-family: "Inter regular", sans-serif;
		font-size: 18px;
		font-weight: 700;
		letter-spacing: 0.46px;
	}	
	.carddatausage {
		color: $black;
		font-size: 36px;
		font-weight: 400;
		letter-spacing: 0.38px;
		font-family: 'Inter Semibold', sans-serif;
		display: inline-block;
		white-space: nowrap;
	}
	.cardusage {
		// width: 137px;
		height: 20px;
		color: #2A7FC9;
		font-size: $font14;
		font-weight: 600;
		letter-spacing: 0.46px;
		line-height: 20px;
	}
	.selected-section {
		font-weight: bold;
	}
	.selected-tab {
		width: 100%;
		height: 7px;
		background-color: #2A7FC9;
		position: $relative;
		top: 8px;
	}
}

.Entertainment {
	border-radius: 3px;
	background-color: $white;
	padding-top: 5px;
	width: 1032px;
	.tabs-wrapper {
		margin-bottom: 18px;
		padding-left: 24px;
		padding-right: 24px;
		padding-top: 8px;
	}
	.card-body {
		height: 361px;
		padding-right: 6px;
		padding-left: 18px;
	}
	.airlinesummarycard:hover {
		& .tab {
			background-color: lightgray;
		}
	}
	.airlinesummarycard {
		.tab {
			width: 100%;
			height: 7px;
			background-color: $white;
			position: $relative;
			top: 8px;
		}
		align-items: start;
		.cardtitle {
			color: $black;
		}
	}
	.box {
		margin: 0 3%;
		background-color: $white;
		text-align: top;
		align-items: top;
	}
	.Title {
		color: $black;
		font-size: 18px;
		font-weight: 600;
		letter-spacing: 0.29px;
	}
	.Entertainment-title {
		color: $black;
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0.26px;
	}
	.Entertainment-data {
		color: $black;
		font-size: 30px;
		font-weight: 500;
		letter-spacing: 0.38px;
	}
}

.Movie-subtitle {
	color: #0065c8;
	font-size: 20px;
	padding: 0px 0px 0px 10px;
	letter-spacing: 0.3px;
}

.builder-DateRangelable {
	padding: 10px;
	color: $black;
	font-size: $font14;
	font-weight: 700;
	letter-spacing: 0.23px;
}

.entertainment-overview-title {
	margin-bottom: 20px;
	color: $black;
	font-weight: 400;
	letter-spacing: 0.3px;
}

.line {
	width: 1px;
	border: 1px solid #eeeeee;
	margin-top: 15px;
	margin-bottom: 15px;
}

.entertainment-totalviewers {
	color: #7d8696;
	font-size: 13px;
	font-weight: 400;
	letter-spacing: 0.43px;
	line-height: 20px;
}

.entertainment-totalviewersdata {
	color: $black;
	font-size: 18px;
	font-weight: 400;
	letter-spacing: 0.29px;
	Margin-bottom: 15px;
}

.entertainment-viewmore.card-footer {
	a,
	.select__single-value,
	.select__dropdown-indicator {
		color: #2A7FC9;
	}
	font-size: 13px;
	font-weight: 400;
	background-color: transparent;
	padding-right: 32px;
}

.entertainment-viewmore.text-left {
	width: 165px;
}

.entertainment-viewmore.text-right {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.CardFooter {
	color: $white;
}

.hidden {
	visibility: hidden;
}

.feedback-submit {
	width: 187px;
	height: 40px;
	border-radius: 2px;
	background-color: #2a7fc9;
	color: #ffffff;
	font-family: "Inter Semibold", sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	margin-left: 24px;
}

.feedback-clear {
	width: 115px;
	height: 40px;
	background-color: #ffffff;
	border: 2px solid #2a7fc9;
	color: #2a7fc9;
	font-family: "Inter Semibold", sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	margin-left: 24px;
}

textarea {
	width: 100%;
	-webkit-box-sizing: border-box;
}

.feedback-submit-div {
	padding-top: 48px;
	margin-bottom: 24px;
	margin-right: 24px;
	float: right;
}

.feedback-card {
	position: relative;
	width: 856px;
	height: 562px;
	box-shadow: 0 1px 2px #ececec;
	border-radius: 3px;
	background-color: #ffffff;
}

.modal-dialog {
	margin-top: 25%;
	margin-left: 28%;
}

.feedback-card-pop {
	width: 856px;
	height: 562px;
	box-shadow: 0 1px 2px #ececec;
	border-radius: 3px;
}

.feedback-headertext {
	width: 639px;
	height: 32px;
	color: #000000;
	font-family: "Inter Semibold", sans-serif;
	font-size: 24px;
	font-weight: 500;
	line-height: 32px;
	padding-top: 18px;
	padding-left: 24px;
}

.feedback-secondheadertext {
	width: 639px;
	height: 32px;
	color: #000000;
	font-family: "Inter Semibold", sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	padding-top: 18px;
	padding-left: 24px;
}

.feedback-stars {
	padding-top: 20px;
	padding-left: 26px;
}

.feedback-textarea {
	width: 808px;
	height: 262px;
	border-radius: 7px;
	font-family: "Inter UI", sans-serif;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 0.35px;
	line-height: 22px;
	margin-left: 24px;
}

.popup {
	position: absolute;
	width: 60%;
	height: 10%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background-color: rgba(0, 0, 0, 0);
}

.popup_inner {
	position: absolute;
	right: 25%;
	bottom: 25%;
	margin: auto;
	background: white;
}

.popup-modal {
	position: absolute;
	width: 552px;
	height: 176px;
	box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.2);
	border-radius: 2px;
	background-color: #ffffff;
}

.popup_button {
	position: absolute;
	top: 0px;
	right: 0px;
}

.popup-submit {
	width: 115px;
	height: 40px;
	border-radius: 2px;
	border: 2px solid #2a7fc9;
	background-color: #2a7fc9;
	color: #ffffff;
	font-family: "Inter Semibold", sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	margin-right: 15px;
	text-align: center;
	margin-top: 24px;
}

.popup-clear {
	width: 115px;
	height: 40px;
	border-radius: 2px;
	border: 2px solid #2a7fc9;
	text-align: center;
	background-color: #ffffff;
	color: #2165a0;
	font-family: "Inter Semibold", sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	margin-right: 15px;
	margin-top: 24px;
}

.modal-first {
	width: 552px;
	height: 176px;
	box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.2);
	border-radius: 2px;
}

.modal-first-body {
	margin-right: 24px;
	float: right;
}

.modal-pop-submit-feedback-text1 {
	width: 504px;
	height: 32px;
	color: #000000;
	font-family: "Inter regular", sans-serif;
	font-size: 24px;
	font-weight: 700;
	line-height: 32px;
	margin-top: 24px;
}

.modal-pop-submit-feedback-text2 {
	width: 504px;
	height: 24px;
	color: #000000;
	font-family: "Inter regular", sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	padding-top: 8px;
}

.manage-detail .details {
	width: 167px;
}

.trend-averageviewingtime {
	width: 100px;
	height: 64px;
	border-radius: 14px;
	background-color: #2a7fc9;
	margin-bottom: 12px;
	display: flex;
	flex-direction: column;
	/* align-content: space-between; */
	/* align-items: center; */
	justify-content: start;
}

.trend-averageviewingtime_headertext {
	/* Style for "Total View" */
	height: 24px;
	color: #ffffff;
	font-family: "Inter regular", sans-serif;
	font-size: 15px;
	font-weight: 400;
	line-height: 18px;
	padding-left: 12px;
	padding-top: 7px;
	padding-bottom: 27px;
	text-transform: capitalize;
}

.trend-averageviewingtime_headervalue {
	/* Style for "354K hr" */
	height: 24px;
	color: #ffffff;
	font-family: "Inter regular", sans-serif;
	font-size: 24px;
	font-weight: 700;
	padding-left: 12px;
	letter-spacing: 0.31px;
	.fas {
		font-size: 25px;
		color: white;
	}
}

.trend-takerate {
	width: 100px;
	height: 64px;
	border-radius: 14px;
	background-color: #79c2d0;
	margin-bottom: 12px;
}

.trend_averagecompletion {
	width: 167px;
	height: 64px;
	border-radius: 14px;
	background-color: #60a5ee;
	margin-bottom: 12px;
}

.trend-image {
    width: 167px;
    height: 248px;
    background-position: center;
    background-size: cover;
}

.trend-image-title {
	width: 590px;
	height: 32px;
	color: #072457;
	font-family: "Inter regular", sans-serif;
	font-size: 24px;
	font-weight: 700;
	line-height: 24px;
}

.trend-performance {
	width: 185px;
	height: 24px;
	color: #000000;
	font-family: "Inter Semibold", sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 16px;
	margin-top: 27px;
	.widget-image {
		margin-left: 0;
		margin-right: 5px;
	}
}

.trend-title-desc {
	width: 145px;
	height: 66px;
	color: #909090;
	font-family: "Inter regular", sans-serif;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 0.51px;
	line-height: 22px;
	padding-bottom: 17px;
}

.ondeck {
	width: 145px;
	color: black;
	font-family: "Inter Semibold", sans-serif;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 0.51px;
	line-height: 21px;
}

.ondeck-text {
	color: #909090;
	font-family: "Inter regular", sans-serif;
	font-size: 16px;
	font-weight: 400;
}

.card-trend {
	width: 1024px;
	height: 482px;
	box-shadow: 0 1px 8px #979797;
	border-radius: 10px;
	background-color: #ffffff;
}

.trend-col {
	flex-grow: 0;
	margin-right: 24px;
}

.trend-line {
	width: 600px;
	background-color: #e0e0e0;
	margin-left: 0px;
    margin-bottom: 30px;
}

.popup {
	width: 100%;
	height: 100%;
	margin-left: 50px;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 10;
}

.popup-shade {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.popup\_inner {
	position: absolute;
	left: 25%;
	right: 25%;
	top: 25%;
	bottom: 25%;
	margin: auto;
	border-radius: 20px;
	background: white;
}

.linechart-pop-align {
	padding-top: 125px;
	float: right;
	max-height: 500px;
	max-width: 500px;
	width: 100%;
	margin-left: -30px;
	//max-height:100%;
}

.pop-totalviewers {
	width: 117px;
	height: 24px;
	color: #909090;
	font-family: "Inter regular", sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 16px;
}

.pop-totalviewers-value {
	width: 153px;
	height: 28px;
	color: #000000;
	font-family: "Inter regular", sans-serif;
	font-size: 24px;
	font-weight: 700;
	letter-spacing: 0.31px;
	padding-top: 7px;
}

.close {
	position: absolute;
	z-index: 999;
	outline: none;
	right: 32px;
	top: 32px;
	width: 32px;
	height: 32px;
	opacity: 0.3;
}

.close:hover {
	opacity: 1;
}

.close:before,
.close:after {
	position: absolute;
	left: 15px;
	content: ' ';
	height: 33px;
	width: 2px;
	background-color: #333;
}

.close:before {
	transform: rotate(45deg);
}

.close:after {
	transform: rotate(-45deg);
}

#market-place {
	.card-body {
		padding-left: 0;
		padding-right: 0;
	}
	.widget-frame {
		height: 316px;
	}
	.chart-wrapper,
	.widget-title {
		margin-left: 32px;
	}
	.Entertainment {
		position: relative;
		background-color: white;
		/*max-width: 1032px;*/
		height: 550px;
		box-shadow: 0 1px 2px #cfc9c9;
		border-radius: 3px;
		grid-column: span 2;
		transition: all .6s ease-in-out;
	}
	.tabs-wrapper {
		margin: auto !important;
		.col {
			max-width: 175px;
		}
		.col:nth-child(4) {
			max-width: 253px;
			.airlinesummarycard {
				max-width: 250px;
			}
		}
		.airlinesummarycard {
			margin-right: 0;
			width: auto;
			max-width: 160px;
			.carddatausage {
				font-size: 1.9rem;
			}
		}
	}
}