$cloudbarWidth: 65px;
$footerHeight: 100px;

$colors: ( panasonic: #0067B1);

.z-100{
  z-index: 1000;
}


body {
  color: rgb(0, 0, 0);
 	font-family: 'Inter regular', sans-serif;
 	height: 100%;
 	min-width: 1024px;
 	overflow-x: hidden;

  min-height: 100vh;
  position: relative;
  margin: 0;


  //padding-left: $cloudbarWidth;
  padding-bottom: $footerHeight;
}


.main-container {
	height: 100%;
	display:flex;
}



.pac-footer-wrapper {
  display: flex;
  justify-content: center;
}

.pac-footer {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;

  width: 30vw;
  min-width: 30em;
  max-width: 50em;
  color: #888888;

  height: $footerHeight;
  line-height: 35px;

  a {
    color: #0067B1;
  }

}

